import React, { useEffect, useRef, useState } from "react";
import "./EventCalendar.scss";
import PropTypes from "prop-types";
import { Badge, Card, Col, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ListLoading from "module/common/ListLoading";
import {
  crmDashboardBootstrapColors,
  crmDashboardColors,
} from "../CrmStats/CrmStatsData";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import EventForm from "./EventForm";
import { getFormattedEndDateTime, stringLimiter } from "helpers/utils";
import moment from "moment";

const EventCalendar = ({
  setRefreshUpcomingSchedule,
  data = [],
  isLoading,
}) => {
  const calendarRef = useRef();
  const Translate = useAxisproTranslate();
  const [calendarApi, setCalendarApi] = useState({});
  const [eventData, setEventData] = useState([{}]);
  const [event, setEvent] = useState({
    show: false,
    data: {
      id: "",
      module: "call",
      start_time: "",
      end_time: "",
    },
  });

  useEffect(() => {
    setEventData(() => ({
      data: data?.map((item, index) => ({
        id: item?.id,
        module: item?.module === "Calling" ? "call" : "meeting",
        color: crmDashboardColors[index % crmDashboardColors.length],
        title: item?.title || item?.agenda,
        start: item?.start_time.split(" ")?.[0],
        allDay: true,
        backgroundColor:
          crmDashboardBootstrapColors[
            index % crmDashboardBootstrapColors.length
          ],
        borderColor:
          crmDashboardBootstrapColors[
            index % crmDashboardBootstrapColors.length
          ],
      })),
    }));
  }, [data]);

  const handleEventClick = (eventsInfo) => {
    setEvent({
      show: true,
      data: {
        id: eventsInfo.id,
        module: eventsInfo?.module,
      },
    });
  };

  function formatDate(dateString) {
    const [day, month, year] = dateString.split("-");
    const dateObj = new Date(`${year}-${month}-${day}`);
    const formattedDay = day.padStart(2, "0");
    const formattedMonth = dateObj.toLocaleString("en-US", { month: "short" });
    const formattedYear = dateObj.getFullYear();
    return `${formattedDay} ${formattedMonth}, ${formattedYear}`;
  }

  const customButtons = {
    myCustomButton: {
      text: Translate("New Schedule"),
      click: () => {
        setEvent({
          show: true,
        });
      },
    },
  };

  useEffect(() => {
    setCalendarApi(calendarRef?.current?.getApi());
  }, []);

  return (
    <>
      <Card className="overflow-hidden h-100 card-main">
        {isLoading ? (
          <div className="p-3">
            <ListLoading style={{ maxHeight: "45vh", overflow: "hidden" }} />
          </div>
        ) : (
          <Card.Body className="p-0 management-calendar">
            <Row className="g-3">
              <Col md={8}>
                <div className="calendar-outline px-3 mt-3">
                  <FullCalendar
                    ref={calendarRef}
                    customButtons={customButtons}
                    headerToolbar={{
                      left: "myCustomButton",
                      center: "",
                      right: "prev today next",
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    viewClassNames={"full-calendar-view-table"}
                    themeSystem="bootstrap"
                    initialView="dayGridMonth"
                    height={400}
                    events={eventData?.data}
                    dateClick={(info) => {
                      setEvent(() => ({
                        show: true,
                        data: {
                          start_time: moment(info.date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                          end_time: getFormattedEndDateTime(
                            moment(info.date),
                            "YYYY-MM-DD HH:mm:ss",
                            "minutes",
                            30
                          ),
                        },
                      }));
                    }}
                  />
                </div>
              </Col>
              <Col md={4} className="bg-body-tertiary pt-3">
                <div className="px-3">
                  <h4 className="mb-0 fs-1 fw-bold">
                    {calendarApi?.currentDataManager?.data?.viewTitle}
                  </h4>
                  <p className="text-500 mb-0">
                    {new Date().toLocaleString("en-us", {
                      weekday: "long",
                    })}
                  </p>
                  {eventData?.data?.length > 0 ? (
                    <ul
                      className="list-unstyled mt-3 scrollbar management-calendar-events"
                      id="management-calendar-events"
                    >
                      {eventData?.data?.map((events) => (
                        <li
                          className="border-top pt-3 mb-3 pb-1 cursor-pointer"
                          onClick={() => handleEventClick(events)}
                          key={events.id}
                        >
                          <div
                            className={`border-start border-3 ps-3 mt-1 border-${events.color}`}
                          >
                            <h6 className="mb-1 fw-semibold text-700">
                              {stringLimiter(events?.title, 18, "-")}
                            </h6>
                            <p className="fs-11 text-600 mb-0">
                              {formatDate(events?.start) || ""}{" "}
                              <Badge bg="warning" pill>
                                {events?.module === "call"
                                  ? "Calling"
                                  : "Meeting"}
                              </Badge>
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div style={{ height: "44vh" }}>
                      <NothingToShow />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
      <EventForm
        show={event?.show}
        onHide={() => setEvent({ show: false })}
        data={event?.data}
        handleRefresh={() => setRefreshUpcomingSchedule((prev) => !prev)}
      />
    </>
  );
};

EventCalendar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      color: PropTypes.string,
      classNames: PropTypes.string,
    })
  ),
};

export default EventCalendar;
