import { React, useState } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import ImportModal from "./ImportModal";
import { FaFileImport } from "react-icons/fa";

function ImportButton({
  title,
  url,
  setLoadAfterImport,
  variant,
  className = "",
  params,
  buttonTitle,
  sampleFileUrl,
}) {
  const Translate = useAxisproTranslate();
  const [modalShow, setModalShow] = useState(false);

  const showImportModel = () => {
    setModalShow(true);
  };

  const handleCancel = () => {
    setModalShow(false);
  };

  return (
    <div className="d-flex">
      <Button
        variant={variant}
        size="sm"
        onClick={showImportModel}
        title={Translate(title ? title : "Import Data")}
        className={`px-2 ${className}`}
      >
        <FaFileImport className="me-1" size={12} />
        {Translate(buttonTitle ? buttonTitle : "Import")}
      </Button>
      <ImportModal
        title={title}
        url={url}
        modalShow={modalShow}
        handleCancel={handleCancel}
        setLoadAfterImport={setLoadAfterImport}
        params={params}
        sampleFileUrl={sampleFileUrl}
      />
    </div>
  );
}

ImportButton.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  setLoadAfterImport: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.any,
  params: PropTypes.object,
  buttonTitle: PropTypes.string,
  sampleFileUrl: PropTypes.string,
};

export default ImportButton;
