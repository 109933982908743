import React from "react";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { useState, useEffect } from "react";
import { getColor, rgbaColor } from "helpers/utils";
import { getPosition } from "helpers/echart-utils";
import { useBreakpoints } from "hooks/useBreakpoints";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import NothingToShow from "../NothingToShow";

const RevenueForecastChart = () => {
  const { breakpoints } = useBreakpoints();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall({
        url: "/crm/lead-opportunity-last-twelve-month-count",
      });
      setData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getOptions = (data) => {
    const months = Object.keys(data);
    const opportunityCount = months.map(
      (month) => data[month].opportunity_count
    );
    const leadCount = months.map((month) => data[month].lead_count);

    return {
      color: [getColor("primary"), getColor("warning")],
      tooltip: {
        trigger: "axis",
        padding: [7, 10],
        backgroundColor: getColor("100"),
        borderColor: getColor("300"),
        textStyle: { color: getColor("dark") },
        borderWidth: 1,
        transitionDuration: 0,
        position(pos, params, dom, rect, size) {
          return getPosition(pos, params, dom, rect, size);
        },
        formatter: function (params) {
          let content = "";
          params.forEach((item) => {
            if (item.seriesName === "Opportunity") {
              content += `Opportunity Count: ${item.value} <br>`;
            }
            if (item.seriesName === "Lead") {
              content += `Lead Count: ${item.value} <br>`;
            }
          });

          return content;
        },
      },

      legend: {
        left: "left",
        top: -5,
        data: ["Lead", "Opportunity"],
        itemWidth: 10,
        itemHeight: 10,
        icon: "circle",
        inactiveColor: getColor("400"),
        textStyle: { color: getColor("700") },
        itemGap: 20,
      },
      xAxis: {
        type: "category",
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        boundaryGap: false,
        axisPointer: {
          lineStyle: {
            color: getColor("300"),
            type: "dashed",
          },
        },
        splitLine: { show: false },
        axisLine: {
          lineStyle: {
            color: rgbaColor("#000", 0.01),
            type: "dashed",
          },
        },
        axisTick: { show: false },
        axisLabel: {
          color: getColor("400"),
          interval: 0,
          margin: 10,
          formatter: (value) => {
            return value;
          },
          rich: {
            bold: {
              fontWeight: "bold",
              fontSize: 12,
            },
          },
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: false,
        axisLabel: {
          show: true,
          color: getColor("400"),
          margin: 20,
        },
      },
      series: [
        {
          type: "line",
          name: "Opportunity",
          data: opportunityCount,
          symbolSize: 8,
          smooth: false,
          lineStyle: { color: rgbaColor(getColor("primary")) },
          itemStyle: {
            borderColor: rgbaColor(getColor("primary"), 0.6),
            borderWidth: 2,
          },
          emphasis: {
            scale: true,
          },
        },
        {
          type: "line",
          name: "Lead",

          data: leadCount,
          symbolSize: 8,
          smooth: false,
          emphasis: {
            scale: true,
          },
          lineStyle: { color: rgbaColor(getColor("warning")), type: "dashed" },
          itemStyle: {
            borderColor: rgbaColor(getColor("warning"), 0.6),
            borderWidth: 2,
          },
          areaStyle: {
            color: "rgba(249, 115, 22, 0.1)",
          },
        },
      ],
      grid: {
        right: "50px",
        left: 15,
        bottom: 30,
        top: 40,
        containLabel: true,
      },
    };
  };
  return (
    <>
      {isLoading ? (
        <ListLoading style={{ maxHeight: "24.5vh", overflow: "hidden" }} />
      ) : (
        <>
          {Object.keys(data).length > 0 ? (
            <ReactEChartsCore
              echarts={echarts}
              option={getOptions(data)}
              style={{ height: breakpoints.up("xxl") ? "23rem" : "15rem" }}
            />
          ) : (
            <div className="h-100">
              <NothingToShow />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RevenueForecastChart;
