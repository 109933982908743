import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Flex from "components/common/Flex";
import SoftBadge from "components/common/SoftBadge";
import ListLoading from "module/common/ListLoading";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import axios from "axios";
import NothingToShow from "../NothingToShow";
import { AuthWizardContext } from "context/Context";

function WeeklyTaskSummary({ amountClassName }) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const userId = user?.id ?? "";
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axios
        .get("crm/dashboard/weekly-task-summary", {
          params: { assigned_to: userId },
        })
        .then((res) => {
          if (res.data.success === true) {
            setData(res.data.data);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    };

    if (userId) fetchData();
  }, [userId]);

  const updatedArray = data?.map((obj) => {
    const formatDate = (dateStr) => {
      const [day, month, year] = dateStr.split("-").map(Number);
      return new Date(year, month - 1, day).toDateString();
    };

    return {
      ...obj,
      from: formatDate(obj.week_start),
      to: formatDate(obj.week_end),
      averageTimeTaken: `${obj.average_time} ${obj.time_unit}`,
    };
  });

  return (
    <Card className={`h-100 card-main`}>
      <Card.Header className="pb-0 ps-2">
        <h6 className="fw-bold fs-0 p-0 mb-2 ms-2">
          {Translate("Weekly Task Summary")}
          {/* <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{Translate("Calculated weekly Tasks")}</Tooltip>}
          >
            <span>
              <FontAwesomeIcon
                icon={["far", "question-circle"]}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger> */}
        </h6>
      </Card.Header>
      {!isLoading && updatedArray ? (
        updatedArray?.length > 0 ? (
          <Swiper
            style={{ width: "100%" }}
            spaceBetween={30}
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay, Navigation]}
            className="mySwiper pb-4 px-2"
          >
            {updatedArray.map((item, key) => (
              <SwiperSlide key={key}>
                <Card.Body
                  as={Flex}
                  alignItems="start"
                  justifyContent="between"
                  className="mb-0 flex-row pt-1 p-2"
                >
                  <div className="d-flex flex-column">
                    <SoftBadge
                      pill
                      bg="success"
                      className="me-2 mt-2 mb-1 fs--2"
                    >
                      {item?.from}
                    </SoftBadge>
                    <SoftBadge pill bg="warning" className="me-2 mb-3 fs--2">
                      {item?.to}
                    </SoftBadge>
                  </div>
                  <div>
                    <div className="d-flex flex-row align-items-center gap-1 justify-content-between gap-3">
                      <p className="m-0 fs--2">
                        <FontAwesomeIcon
                          icon="circle"
                          className="text-info fs--2 me-2"
                        />
                        {Translate("Total Tasks")}
                      </p>
                      <h5 className="m-0 fs--2 text-info">
                        {item?.total_task}
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-1 justify-content-between gap-3">
                      <p className="m-0 fs--2">
                        <FontAwesomeIcon
                          icon="circle"
                          className="text-warning fs--2 me-2"
                        />
                        {Translate("Total Completed Tasks")}
                      </p>
                      <h5 className="m-0 fs--2 text-warning">
                        {item?.completed_task}
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-1 justify-content-between gap-3">
                      <p className="m-0 fs--2">
                        <FontAwesomeIcon
                          icon="circle"
                          className="text-success fs--2 me-2"
                        />
                        {Translate("Total Pending Tasks")}
                      </p>
                      <h5 className="m-0 fs--2 text-success">
                        {item?.pending_task}
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-1 justify-content-between gap-3">
                      <p className="m-0 fs--2">
                        <FontAwesomeIcon
                          icon="circle"
                          className="text-danger fs--2 me-2"
                        />
                        {Translate("Average Time Taken")}
                      </p>
                      <h5 className="m-0 fs--2 text-danger">
                        {item?.averageTimeTaken}
                      </h5>
                    </div>
                  </div>
                </Card.Body>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            <div className="h-100 py-3">
              <NothingToShow />
            </div>
          </>
        )
      ) : (
        <div className="p-3">
          <ListLoading style={{ maxHeight: "9.7vh", overflow: "hidden" }} />
        </div>
      )}
    </Card>
  );
}

export default WeeklyTaskSummary;
