import useAxisproTranslate from "hooks/useAxisproTranslate";
import React from "react";
import { Alert } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

function KycDataTable({ formData, handleRemoveFile }) {
  const Translate = useAxisproTranslate();
  return (
    <>
      {formData?.kyc_documents?.length > 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr className="bg-light">
                  <th className="text-center fw-semi-bold text-light">
                    {Translate("S/L")}
                  </th>
                  <th className="text-center fw-semi-bold text-light px-2">
                    {Translate("Document Type")}
                  </th>
                  <th className="text-center fw-semi-bold text-light px-2">
                    {Translate("File Name")}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formData?.kyc_documents?.map((item, index) => (
                  <tr key={index}>
                    <td className="p-1 text-center text-dark">{index + 1}</td>
                    <td className="p-1 text-center text-dark">
                      {item?.document_type_ref?.label}
                    </td>
                    <td className="p-1 text-center text-dark">
                      {item?.files?.name}
                    </td>
                    <td className="p-1 text-center text-dark">
                      <FaTrash
                        size={12}
                        className="cursor-pointer"
                        title="Remove"
                        onClick={() => handleRemoveFile(index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center mt-2">
          <Alert variant="warning" className="text-muted">
            {Translate("No KYC documents added")}
          </Alert>
        </div>
      )}
    </>
  );
}

export default KycDataTable;
