import { React, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import FalconCardFooterLink from "components/common/FalconCardFooterLink";
import FalconCardHeader from "components/common/FalconCardHeader";
import useAdvanceTable from "hooks/useAdvanceTable";
import ListLoading from "module/common/ListLoading";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import ProjectOffcanvas from "module/Project/ProjectOffcanvas/ProjectOffcanvas";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { removeHtmlTagsFromString } from "helpers/utils";
import TableOverlayTrigger from "components/table-overlay-trigger/TableOverlayTrigger";
import { FaCircle } from "react-icons/fa";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import RunningProjectAdvanceTable from "../RunningProjects/RunningProjectAdvanceTable";
import Flex from "components/common/Flex";
import TaskInfo from "module/Task/TaskInfo/TaskInfo";
import { createSearchParams } from "react-router-dom";
import { AuthWizardContext } from "context/Context";

const uncompletedTaskstatusFilters = [
  "to_do",
  "in_progress",
  "hold",
  "need_clarification",
  "bug",
  "reopened",
];

const CriticalTasks = () => {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const userId = user?.id ?? "";
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axios
        .get("crm/task", {
          params: {
            // critical_task_for_dashboard: 1,
            // priority: "Critical",
            assignee: userId,
            status_filter: uncompletedTaskstatusFilters,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            setData(res.data.data.data);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    };
    if (userId) fetchData();
  }, [userId]);

  const permission = {
    showTask: GetAcodaxPermission("TASK", "show"),
    showProject: GetAcodaxPermission("PROJECT", "show"),
    showAssigned: GetAcodaxPermission("AT", "view"),
    showCreated: GetAcodaxPermission("CT", "view"),
  };

  const taskShowPermission =
    permission?.showAssigned || permission?.showCreated || permission?.showTask;

  const [taskInfo, setTaskInfo] = useState({
    id: "",
    show: false,
  });

  const [projectInfo, setProjectInfo] = useState({
    show: false,
    id: "",
  });

  const handleShowProjectInfo = (id) => {
    setProjectInfo({
      show: true,
      id: id,
    });
  };

  const handleTaskInfo = (id) => {
    if (id) {
      setTaskInfo({
        id: id,
        show: true,
      });
    }
  };

  const columns = [
    {
      accessorKey: "task_number",
      header: Translate("Task No"),
      meta: {
        headerProps: { className: "text-800 fw-semi-bold text-center" },
        cellProps: { className: "text-center" },
      },
      cell: ({ row: { original } }) => {
        const { task_number } = original;
        return <>#{task_number}</>;
      },
    },
    {
      accessorKey: "title",
      header: Translate("Title"),
      meta: {
        headerProps: {
          className: "text-800 fw-semi-bold",
        },
      },
      cell: ({ row: { original } }) => {
        const { title, description, id } = original;
        return (
          <OverlayTrigger
            overlay={title?.length > 35 ? <Tooltip>{title}</Tooltip> : <></>}
          >
            <div className="d-flex flex-column text-nowrap">
              <span
                className={`${
                  taskShowPermission ? "link-like-text" : ""
                } text-dark`}
                onClick={() => {
                  taskShowPermission && handleTaskInfo(id);
                }}
              >
                {title?.length > 35 ? `${title?.slice(0, 35)}...` : title}
              </span>
              <div className="fs--2 text-wrap">
                <div
                  className="setInnerHtml"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "15rem",
                  }}
                >
                  {removeHtmlTagsFromString(description)}
                </div>
              </div>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      accessorKey: "project_title",
      header: Translate("Project"),
      meta: {
        cellProps: {
          className: "text-center fw-semibold fs-10",
        },
        headerProps: {
          className: "fw-semi-bold text-800",
        },
      },
      cell: ({ row: { original } }) => {
        const { project_title, project_id } = original;
        return (
          <Flex alignItems="start" className="position-relative">
            <div>
              <h6 className="mb-0 fw-semibold">
                {permission?.showProject ? (
                  <div
                    className="link-like-text stretched-link text-900"
                    onClick={() => handleShowProjectInfo(project_id)}
                  >
                    {project_title}
                  </div>
                ) : (
                  <p className="mb-0 text-900 text-capitalize">
                    {project_title}
                  </p>
                )}
              </h6>
            </div>
          </Flex>
        );
      },
    },
    {
      accessorKey: "status",
      header: Translate("Status"),
      meta: {
        cellProps: {
          className: "text-center fw-semibold fs-10",
        },
        headerProps: {
          className: "fw-semi-bold text-800",
        },
      },
      cell: ({ row: { original } }) => {
        const { status } = original;
        return (
          <div>
            <span
              className="text-capitalize d-flex align-items-center"
              style={{ width: "max-content" }}
            >
              <FaCircle color={getTaskStatusWithProps(status)} size={10} />
              <span className="ms-1">{status.replace("_", " ")}</span>
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "start_date_formatted",
      header: Translate("Start Date"),
      enableSorting: false,
      meta: {
        headerProps: {
          className: "text-800 fw-semi-bold",
        },
      },
      cell: ({ row: { original } }) => {
        const { start_date_formatted } = original;
        return (
          <span className="fs--1">
            {start_date_formatted ? start_date_formatted : "-"}
          </span>
        );
      },
    },
    {
      accessorKey: "end_date_formatted",
      header: Translate("End Date"),
      enableSorting: false,
      meta: {
        headerProps: {
          className: "text-800 fw-semi-bold",
        },
      },
      cell: ({ row: { original } }) => {
        const { end_date_formatted } = original;
        return (
          <span className="fs--1">
            {end_date_formatted ? end_date_formatted : "-"}
          </span>
        );
      },
    },
    {
      accessorKey: "created_user_full_name",
      header: Translate("Created By"),
      enableSorting: false,
      meta: {
        headerProps: {
          className: "text-800 fw-semi-bold",
        },
      },
      cell: ({ row: { original } }) => {
        const { created_user_full_name, created_by } = original;
        return (
          <span className="fs--1">
            <TableOverlayTrigger
              content={created_user_full_name}
              id={created_by}
              length={15}
            />
          </span>
        );
      },
    },
  ];

  const table = useAdvanceTable({
    data,
    columns,
    striped: true,
    pagination: true,
    perPage: 5,
  });

  return (
    <>
      <Card className="h-100 card-main">
        <FalconCardHeader
          title="My Task"
          titleTag="h6"
          titleClass="fw-bold fs-0 mt-1"
        />
        <Card.Body className="p-0">
          {isLoading ? (
            <div className="px-3 pb-3">
              <ListLoading
                style={{ maxHeight: "28.5vh", overflow: "hidden" }}
              />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <RunningProjectAdvanceTable
                  table={table}
                  headerClassName="bg-body-tertiary text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    borderless: true,
                    striped: true,
                    className: "fs-11 mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="h-100">
                  <NothingToShow />
                </div>
              )}
            </>
          )}
        </Card.Body>
        <Card.Footer className="p-0">
          <FalconCardFooterLink
            title="Show all Tasks"
            size="sm"
            to={`/tasks/list?${createSearchParams({
              assignee: user?.id,
              assignee_ref: JSON.stringify({
                value: user?.id,
                label: user?.name,
              }),
              status_filter: uncompletedTaskstatusFilters?.join("/"),
              status_filter_ref: JSON.stringify(
                uncompletedTaskstatusFilters?.map((item) => ({
                  label: item.replace("_", " "),
                  value: item,
                }))
              ),
            })}`}
          />
        </Card.Footer>
      </Card>
      <ProjectOffcanvas
        show={projectInfo?.show}
        id={projectInfo?.id}
        onHide={() => setProjectInfo({ show: false, id: "" })}
      />
      <TaskInfo
        taskInfoId={taskInfo?.id}
        showTaskInfo={taskInfo?.show}
        handleCloseTaskInfo={() => {
          setTaskInfo({ show: false, id: "" });
        }}
      />
    </>
  );
};

CriticalTasks.propTypes = {
  data: PropTypes.array,
};

export default CriticalTasks;
