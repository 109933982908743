import { React, useEffect, useState } from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import PropTypes from "prop-types";
import SalesChart from "./SalesChart";
// import SalesDropdown from "./SalesDropdown";
import SimpleBarReact from "simplebar-react";
import TabTitle from "./TabTitle";
import axios from "axios";
import ListLoading from "module/common/ListLoading";
import FalconCardHeader from "components/common/FalconCardHeader";
import NothingToShow from "../NothingToShow";

const dayAbbreviations = {
  Sunday: "SUN",
  Monday: "MON",
  Tuesday: "TUE",
  Wednesday: "WED",
  Thursday: "THU",
  Friday: "FRI",
  Saturday: "SAT",
};

const SalesPipeLine = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchData = () => {
    setLoading(true);
    axios
      .get("crm/leads-status-count-and-recent-leads")
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const leadStatusValue = Object.entries(
    data?.current_week_lead_status || []
  )?.map(([title, values]) => ({
    title,
    ...values,
  }));

  const leadCountsInLastSevenDays = [
    data?.weekly_lead_count_by_day?.map(
      (item) => dayAbbreviations[item.day] || item.day
    ),
    data?.weekly_lead_count_by_day?.map((item) => item.count),
  ];

  return (
    <Card className="card-main" style={{ height: "100%" }}>
      <>
        <div className="d-flex justify-content-between">
          <FalconCardHeader
            title="Sales Pipeline Overview"
            titleTag="h6"
            titleClass="fw-bold fs-0 px-0"
          />
          {/* <div>
            <SalesDropdown />
          </div> */}
        </div>
        {loading ? (
          <>
            <Card.Body className="p-3">
              <ListLoading
                style={{ maxHeight: "30.4vh", overflow: "hidden" }}
              />
            </Card.Body>
          </>
        ) : (
          <>
            {Object.keys(data).length > 0 ? (
              <Tab.Container
                id="audience-tab"
                defaultActiveKey="newleads"
                className="p-2"
              >
                <SimpleBarReact>
                  <Card.Header className="p-0 bg-body-tertiary ">
                    <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
                      {leadStatusValue?.map((item, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link className="mb-0 p-0" eventKey="newleads">
                            <TabTitle
                              title={item?.title}
                              value={item?.count}
                              progress={true}
                              percentage={item?.percentage}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Card.Header>
                </SimpleBarReact>
                <Card.Body>
                  <Row className="g-1">
                    <Col xxl={12}>
                      <Tab.Content>
                        <Tab.Pane unmountOnExit eventKey="newleads">
                          <SalesChart data={leadCountsInLastSevenDays} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Card.Body>
              </Tab.Container>
            ) : (
              <div className="h-100">
                <NothingToShow />
              </div>
            )}
          </>
        )}
      </>
    </Card>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  progress: PropTypes.bool,
};

export default SalesPipeLine;
