import useAxisproTranslate from "hooks/useAxisproTranslate";
import ListLoading from "module/common/ListLoading";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  BsFillPersonCheckFill,
  BsFillPersonXFill,
  BsFillPersonLinesFill,
  BsPersonWorkspace,
} from "react-icons/bs";
import PropTypes from "prop-types";

function TicketCard({ icon: Icon, iconColor, label, value, loading }) {
  return (
    <Card className="h-100 card-main p-3">
      {loading ? (
        <ListLoading style={{ maxHeight: "6vh", overflow: "hidden" }} />
      ) : (
        <Row className="align-items-start">
          <Col xs="auto">
            <Icon size={30} color={iconColor} />
          </Col>
          <Col className="p-0">
            <div className="text-center">
              <h6 className="mb-3 flex-1 fw-bold fs-0 p-0">{label}</h6>
            </div>
            <div className="text-center">
              <h3 className="fw-semi-bold text-600 fs-1">{value}</h3>
            </div>
          </Col>
        </Row>
      )}
    </Card>
  );
}

function OpenTicketsGroup({ ticketSummary, ticketSummaryLoading }) {
  const Translate = useAxisproTranslate();
  const tickets = [
    {
      icon: BsFillPersonCheckFill,
      iconColor: "#28a745",
      label: Translate("Assigned Tickets"),
      value: ticketSummary?.assigned_open_tickets,
    },
    {
      icon: BsFillPersonXFill,
      iconColor: "#dc3545",
      label: Translate("Unassigned Tickets"),
      value: ticketSummary?.unassigned_open_tickets,
    },
    {
      icon: BsFillPersonLinesFill,
      iconColor: "#007bff",
      label: Translate("My Tickets"),
      value: ticketSummary?.my_tickets,
    },
    {
      icon: BsPersonWorkspace,
      iconColor: "#6f42c1",
      label: Translate("Tickets By Me"),
      value: ticketSummary?.tickets_by_me,
    },
  ];

  return (
    <Row className="g-3 h-100">
      {tickets.map((ticket, index) => (
        <Col key={index} xxl={12} xl={12} lg={12} md={3} sm={6}>
          <TicketCard
            icon={ticket.icon}
            iconColor={ticket.iconColor}
            label={ticket.label}
            value={ticket.value}
            loading={ticketSummaryLoading}
          />
        </Col>
      ))}
    </Row>
  );
}

OpenTicketsGroup.propTypes = {
  ticketSummary: PropTypes.any,
  ticketSummaryLoading: PropTypes.bool,
};

export default OpenTicketsGroup;
