import { React, useCallback, useContext, useEffect, useState } from "react";
import { Badge, Card, Image } from "react-bootstrap";
import axios from "axios";
import FalconCardFooterLink from "components/common/FalconCardFooterLink";
import FalconCardHeader from "components/common/FalconCardHeader";
import useAdvanceTable from "hooks/useAdvanceTable";
import ListLoading from "module/common/ListLoading";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import ProjectOffcanvas from "module/Project/ProjectOffcanvas/ProjectOffcanvas";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { removeHtmlTagsFromString, stringLimiter } from "helpers/utils";
import { FaCircle } from "react-icons/fa";
import RunningProjectAdvanceTable from "../RunningProjects/RunningProjectAdvanceTable";
import { createSearchParams } from "react-router-dom";
import { AuthWizardContext } from "context/Context";
import ProfileImage from "../../../assets/img/Avathar/ProfileImage.jpg";
import getTicketStatusWithProps from "module/common/helpers/getTicketStatusWithProps";
import TicketInfo from "module/Tickets/TicketInfo/TicketInfo";
import CustomerDetails from "components/customer-details/CustomerDetails";
// import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";

const CriticalTickets = () => {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const userId = user?.id ?? "";
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(() => {
    if (!userId) return;
    setIsLoading(true);
    axios
      .get("crm/ticket", {
        params: {
          // top_critical_tickets_for_dashboard: 1,
          // priority: "Critical",
          assignee: userId,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.data.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const permission = {
    showTicket: GetAcodaxPermission("TICKET", "show"),
    showProject: GetAcodaxPermission("PROJECT", "show"),
    showCustomer: GetAcodaxPermission("CUSTOMER", "show"),
  };

  const [ticketInfo, setTicketInfo] = useState({
    data: "",
    show: false,
  });

  const [projectInfo, setProjectInfo] = useState({
    show: false,
    id: "",
  });

  const [customerData, setCustomerData] = useState({
    showCustomerInfo: false,
    value: { value: "" },
    label: "",
  });

  // const [userProfile, setUserProfile] = useState({
  //   show: false,
  //   id: "",
  // });

  const handleCustomerDetailsArea = (name, id) => {
    setCustomerData({ label: name, value: { value: id }, show: true });
  };

  const handleShowProjectInfo = (id) => {
    setProjectInfo({
      show: true,
      id: id,
    });
  };

  const handleTaskInfo = (data) => {
    setTicketInfo({
      data: data,
      show: true,
    });
  };

  // const handleUserProfileArea = (id) => {
  //   setUserProfile({
  //     show: true,
  //     id: id,
  //   });
  // };

  const columns = [
    {
      accessorKey: "reference",
      header: Translate("Reference"),
      meta: {
        headerProps: { className: "text-800 fw-semi-bold" },
      },
      cell: ({ row: { original } }) => {
        const { reference } = original;
        return (
          <>
            {" "}
            {reference ? (
              <Badge className="fs--2" bg="info">
                {reference}
              </Badge>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "title",
      header: Translate("Ticket Title"),
      meta: {
        headerProps: {
          className: "text-800 fw-semi-bold",
        },
      },
      cell: ({ row: { original } }) => {
        const { title, description } = original;
        return (
          <div className="d-flex flex-column text-nowrap">
            <span
              className={`${
                permission.showTicket ? "link-like-text " : ""
              } text-800`}
              onClick={() => {
                permission.showTicket && handleTaskInfo(original);
              }}
            >
              {stringLimiter(title, 35, "-")}
            </span>
            <div className="fs--2 text-wrap">
              <div
                className="setInnerHtml"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "15rem",
                }}
              >
                {removeHtmlTagsFromString(description)}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "project",
      header: Translate("Project"),
      meta: {
        cellProps: {
          className: "text-center fs-10",
        },
        headerProps: {
          className: "text-center fw-semi-bold text-800",
        },
      },
      cell: ({ row: { original } }) => {
        const { project, project_id } = original;
        return (
          <h6 className="mb-0">
            {permission?.showProject ? (
              <div
                className="link-like-text text-800"
                onClick={() => handleShowProjectInfo(project_id)}
              >
                {project}
              </div>
            ) : (
              <p className="mb-0 text-800">{project}</p>
            )}
          </h6>
        );
      },
    },
    {
      accessorKey: "customer_id",
      header: Translate("Client"),
      meta: {
        cellProps: {
          className: "text-center fs-10",
        },
        headerProps: {
          className: "text-center fw-semi-bold text-800",
        },
      },
      cell: ({ row: { original } }) => {
        const { customer_image, customer_name, customer_id } = original;
        return (
          <>
            <div
              className={`d-flex align-items-center justify-content-center ${
                permission?.showCustomer ? "link-like-text " : ""
              } text-800`}
              onClick={() => {
                permission?.showCustomer &&
                  handleCustomerDetailsArea(
                    customer_name ?? "",
                    customer_id ?? ""
                  );
              }}
            >
              <Image
                className="custom-image-crm"
                src={customer_image ? customer_image : ProfileImage}
                roundedCircle
                alt="?"
                loading="lazy"
                height={"25vh"}
                width={"25vh"}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = ProfileImage;
                }}
              />
              <p className="ps-1 m-0" title={customer_name}>
                {stringLimiter(customer_name, 20, "-")}
              </p>
            </div>
          </>
        );
      },
    },
    // {
    //   accessorKey: "ticket_origin",
    //   header: Translate("Origin"),
    //   meta: {
    //     cellProps: {
    //       className: "text-center fs-10",
    //     },
    //     headerProps: {
    //       className: "text-center fw-semi-bold text-800",
    //     },
    //   },
    //   cell: ({ row: { original } }) => {
    //     const { ticket_origin } = original;
    //     return (
    //       <h5 className="mb-0 fs--1 text-capitalize">
    //         {ticket_origin ? ticket_origin : "-"}
    //       </h5>
    //     );
    //   },
    // },
    {
      accessorKey: "status",
      header: Translate("Status"),
      meta: {
        cellProps: {
          className: "text-center fs-10",
        },
        headerProps: {
          className: "text-center fw-semi-bold text-800",
        },
      },
      cell: ({ row: { original } }) => {
        const { status } = original;
        return (
          <div className="text-center">
            <span className="d-flex align-items-center justify-content-center">
              <FaCircle color={getTicketStatusWithProps(status)} size={10} />
              <span className="ms-1">
                {getTicketStatusWithProps(status, "label")}
              </span>
            </span>
          </div>
        );
      },
    },
    // {
    //   accessorKey: "created_by",
    //   header: Translate("Created By"),
    //   meta: {
    //     cellProps: {
    //       className: "text-center fs-10",
    //     },
    //     headerProps: {
    //       className: "text-center fw-semi-bold text-800",
    //     },
    //   },
    //   cell: ({ row: { original } }) => {
    //     const { created_user_full_name, created_by } = original;
    //     return (
    //       <div
    //         className="d-flex align-items-center justify-content-center link-like-text text-800"
    //         onClick={() => {
    //           handleUserProfileArea(created_by);
    //         }}
    //       >
    //         <span className="d-inline">
    //           <Image
    //             className="custom-image-crm"
    //             src={ProfileImage}
    //             roundedCircle
    //             alt="?"
    //             loading="lazy"
    //             height={"25vh"}
    //             width={"25vh"}
    //             onError={(e) => {
    //               e.target.onerror = null;
    //               e.target.src = ProfileImage;
    //             }}
    //           />
    //         </span>
    //         <span className="d-inline p-0 ms-2 d-flex align-items-center">
    //           <p className="m-0 text-800">
    //             {stringLimiter(created_user_full_name, 18, "-")}
    //           </p>
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      accessorKey: "ticket_date_formated",
      header: Translate("Start Date"),
      enableSorting: false,
      meta: {
        cellProps: {
          className: "text-center",
        },
        headerProps: {
          className: "text-center text-800 fw-semi-bold",
        },
      },
      cell: ({ row: { original } }) => {
        const { ticket_date_formated } = original;
        return (
          <span className="fs--1">
            {ticket_date_formated ? ticket_date_formated : "-"}
          </span>
        );
      },
    },
    {
      accessorKey: "end_date_formated",
      header: Translate("End Date"),
      enableSorting: false,
      meta: {
        cellProps: {
          className: "text-center",
        },
        headerProps: {
          className: "text-center text-800 fw-semi-bold",
        },
      },
      cell: ({ row: { original } }) => {
        const { end_date_formated } = original;
        return (
          <span className="fs--1">
            {end_date_formated ? end_date_formated : "-"}
          </span>
        );
      },
    },
  ];

  const table = useAdvanceTable({
    data,
    columns,
    pagination: true,
    perPage: 5,
  });

  return (
    <>
      <Card className="h-100 card-main">
        <FalconCardHeader
          title="My Tickets"
          titleTag="h6"
          titleClass="fw-bold fs-0 mt-1"
        />
        <Card.Body className="p-0">
          {isLoading ? (
            <div className="px-3 pb-3">
              <ListLoading
                style={{ maxHeight: "23.5vh", overflow: "hidden" }}
              />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <RunningProjectAdvanceTable
                  table={table}
                  headerClassName="bg-body-tertiary text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    borderless: true,
                    striped: true,
                    className: "fs-11 mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="h-100">
                  <NothingToShow />
                </div>
              )}
            </>
          )}
        </Card.Body>
        <Card.Footer className="p-0">
          <FalconCardFooterLink
            title="Show all Tickets"
            size="sm"
            to={`/tickets/list?${createSearchParams({
              assignee: user?.id,
              assignee_ref: JSON.stringify({
                value: user?.id,
                label: user?.name,
              }),
              order_by: "desc",
              order_by_ref: JSON.stringify({
                value: "desc",
                label: "Descending",
              }),
            })}`}
          />
        </Card.Footer>
      </Card>
      <ProjectOffcanvas
        show={projectInfo?.show}
        id={projectInfo?.id}
        onHide={() => setProjectInfo({ show: false, id: "" })}
      />
      <TicketInfo
        data={ticketInfo?.data}
        show={ticketInfo?.show}
        onHide={() => {
          setTicketInfo({ show: false, data: "" });
        }}
        getDataRefresh={fetchData}
      />
      <CustomerDetails
        show={customerData?.show}
        onHide={() =>
          setCustomerData({ show: false, value: { value: "" }, label: "" })
        }
        value={customerData?.value}
        showCustomerDetailsArea={customerData?.show}
      />
      {/* <UserProfile
        show={userProfile?.show}
        handleClose={() => setUserProfile(() => ({ show: false, id: "" }))}
        id={userProfile?.id}
      /> */}
    </>
  );
};

export default CriticalTickets;
