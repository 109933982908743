import { React, useEffect, useState } from "react";
import {
  createSearchParams,
  useParams,
  useSearchParams,
  Link,
  useLocation,
} from "react-router-dom";
import { Button, Card } from "react-bootstrap";

import { BsGrid, BsList } from "react-icons/bs";
import { AiOutlineCodeSandbox } from "react-icons/ai";

import { GRID, LIST } from "./View/constants";
import { commonProjectFilterFormKeys } from "helpers/formKeys";
import { AddButton } from "module/common/Buttons/AppButtons";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import ExportButton from "components/export-button/ExportButton";
import CommonFilter from "components/filter/CommonFilter";
import RefreshButton from "components/refresh-button/RefreshButton";
import TableHeader from "helpers/tableHeader";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import ImportButton from "components/ImportButton/ImportButton";

const ProjectTableHeader = ({
  children,
  getData,
  loading,
  openFrom,
  filterVisibility,
  setFilterVisibility,
}) => {
  const location = useLocation();
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const activeView = queryParams.get("view");
  const pageNumber = queryParams.get("page");
  const { itemInfoId, client_id, empId } = useParams();
  const [filterFields, setFilterFields] = useState(commonProjectFilterFormKeys);
  const {
    from_date,
    to_date,
    date_range_for,
    project_id,
    title,
    account_id,
    status,
    product_id,
  } = allQueryParams;
  const isFromEmployee = openFrom === "employee";
  const isFromAccount = openFrom === "account";
  const permission = {
    export: GetAcodaxPermission("PROJECT", "export"),
    store: GetAcodaxPermission("PROJECT", "store"),
    import: GetAcodaxPermission("PROJECT", "import"),
  };

  useEffect(() => {
    const productIdFilter = product_id ? product_id?.split("/") : [];
    setFilterFields((prevFields) => ({
      ...prevFields,
      from_date,
      to_date,
      date_range_for,
      project_id,
      title,
      account_id,
      status,
      product_id: productIdFilter,
    }));
  }, [
    from_date,
    to_date,
    date_range_for,
    project_id,
    title,
    account_id,
    status,
    product_id,
  ]);

  return (
    <Card className={openFrom ? "shadow-none flex-fill" : "flex-fill mb-3"}>
      <Card.Header
        className={`d-flex flex-row justify-content-between align-items-center flex-wrap gap-2 ${
          itemInfoId ? "d-none" : ""
        } ${!filterVisibility ? "border-bottom" : ""}`}
      >
        <div className="d-flex align-items-center">
          <AiOutlineCodeSandbox size={22} className="me-1 text-dark" />
          <TableHeader title={Translate("PROJECT LIST")} table />
        </div>
        <div className="d-flex flex-wrap gap-1">
          <RefreshButton
            style={{ fontSize: "11px" }}
            loading={loading}
            className={"d-flex align-items-center justify-content-center"}
            variant={"info"}
            onClick={() => getData(pageNumber)}
          />
          {permission?.import && !openFrom && (
            <ImportButton
              title="Import Project"
              url="settings/import-project"
              setLoadAfterImport={getData}
              variant="success"
              className={"fs--2"}
              buttonTitle={"Import"}
              sampleFileUrl={`settings/import-project-sample`}
            />
          )}
          {permission?.export && !openFrom && (
            <ExportButton
              url="crm/project-export"
              initialMessage={"Project export started"}
              successMessage={"Project export completed"}
              file_type={"pdf"}
              variant={"danger"}
              filters={filterFields}
            />
          )}
          {permission?.store && (
            <AddButton
              route={
                openFrom
                  ? `project/add?${createSearchParams({ ...allQueryParams })}`
                  : `/project/add?${createSearchParams({
                      ...allQueryParams,
                    })}`
              }
              variant="success"
              title={"Add Project"}
            />
          )}
          <FilterCommonButton
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
          />
          <Button
            variant="transparent"
            size="sm"
            className="list-add-button"
            style={{ boxShadow: "none" }}
            title={"Grid view"}
            as={Link}
            to={
              isFromEmployee
                ? `/employee/overview/${empId}?${createSearchParams({
                    ...allQueryParams,
                    view: activeView === LIST ? GRID : LIST,
                  })}`
                : isFromAccount
                ? `/account/overview/${client_id}?${createSearchParams({
                    ...allQueryParams,
                    view: activeView === LIST ? GRID : LIST,
                  })}`
                : `/project?${createSearchParams({
                    ...allQueryParams,
                    view: activeView === LIST ? GRID : LIST,
                  })}`
            }
          >
            {activeView === LIST ? <BsGrid size={20} /> : <BsList size={20} />}
          </Button>
        </div>
      </Card.Header>
      {/* !location.pathname.includes("info") - This code used to hide filter in project view page - Need to findout how this rendering in view page and remove this code */}
      {filterVisibility && !location.pathname.includes("info") && (
        <CommonFilter
          filterKeys={{
            ...commonProjectFilterFormKeys,
            view: activeView ? activeView : GRID,
          }}
          module={"project"}
          additionalResetParams={{
            unassigned: "",
            tab: openFrom ? "projects" : "",
          }}
        />
      )}
      {!itemInfoId && loading ? (
        <LoadingScreenCover
          className={`flex-fill shadow-none`}
          style={{ height: openFrom ? "60vh" : "" }}
        >
          <LoadingCommon loadingText="Looking for project!" />
        </LoadingScreenCover>
      ) : (
        <Card.Body className="p-0">{children}</Card.Body>
      )}
    </Card>
  );
};

export default ProjectTableHeader;
