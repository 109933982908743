import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import StatsChart from "./StatsChart";
import { stats } from "./StatsData";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const Stats = ({ data, isLoading }) => {
  const Translate = useAxisproTranslate();
  const array = ["Total Projects", "Running Projects", "Completed Projects"];
  return (
    <Card className="h-40 card-main">
      <Card.Body className="p-3">
        <Row className="g-5 g-sm-0">
          {array.map((item, index) => (
            <Col sm={4} key={index}>
              {isLoading ? (
                <Card.Body>
                  <DashboardTileLoader column={1} />
                </Card.Body>
              ) : (
                <div
                  className={`${
                    index === 0 || index === 1 ? "border-sm-end border-300" : ""
                  } py-1`}
                >
                  <div className="text-center">
                    <h6 className="mb-3 flex-1 fw-bold fs-0 p-0">
                      {Translate(item)}
                    </h6>
                  </div>
                  {Object.keys(data).length > 0 ? (
                    <>
                      <div className="text-center">
                        <h3 className="fw-semi-bold text-600 fs-1">
                          {item === "Total Projects"
                            ? data.project_count
                            : item === "Running Projects"
                            ? data?.running_project_count
                            : data?.completed_projects_count}
                        </h3>
                      </div>
                      <StatsChart
                        data={stats[index].chartData}
                        grid={stats[index].grid}
                      />{" "}
                    </>
                  ) : (
                    <div className="mt-3">
                      <NothingToShow />
                    </div>
                  )}
                </div>
              )}
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Stats;
