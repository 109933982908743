import React from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  FaEdit,
  FaEye,
  FaPlayCircle,
  FaPlus,
  FaRegStopCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TbBellRingingFilled, TbClockBolt } from "react-icons/tb";
import { BiCopyAlt, BiEditAlt, BiTrash } from "react-icons/bi";
import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";
import { IoCloseSharp, IoSearchSharp, IoShuffle } from "react-icons/io5";

export const AddButton = ({
  route,
  title,
  variant,
  label,
  className,
  isDisabled = false,
}) => {
  return (
    <Button
      size="sm"
      className={className ?? className + "m-1 ms-0"}
      variant={variant}
      title={title}
      as={Link}
      to={route}
      disabled={isDisabled}
    >
      {label ? label : <FaPlus size={10} />}
    </Button>
  );
};

AddButton.propTypes = {
  route: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

export const ReferenceButton = ({ title, route, variant, label }) => {
  return (
    <Button
      variant={variant}
      className="custom-button-action"
      size="sm"
      as={Link}
      to={route}
      title={label}
    >
      <span className="d-block text-capitalize">{title}</span>
    </Button>
  );
};

ReferenceButton.propTypes = {
  route: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
};

export const EditButton = ({
  margin = "m-1",
  iconSize = 15,
  route,
  className,
  title = "",
  onClick,
  disabled = false,
  label = "Edit",
}) => {
  const handleClick = () => {
    if (onClick && typeof onClick === "function") {
      onClick();
    }
  };
  return (
    <Button
      size="sm"
      variant="transparent"
      className={`custom-button-action p-0 ${className ? className : ""}`}
      as={!disabled && !onClick && Link}
      to={!disabled && !onClick && route}
      title={label}
    >
      <div className="d-flex justify-content-center align-items-center">
        <FaEdit
          color={disabled ? "#c4c4c4" : "#f18615cc"}
          className={`${margin} ${
            disabled ? "text-muted" : "custom-button-edit-icon"
          }`}
          size={iconSize}
          onClick={() => !disabled && handleClick()}
        />
        {title && <span className="ms-2 fw-semibold mt-1">{title}</span>}
      </div>
    </Button>
  );
};

EditButton.propTypes = {
  route: PropTypes.string,
};

export const DeleteButton = ({
  margin = "m-1",
  iconSize = 15,
  onClick,
  className,
  title = "",
  disabled = false,
  label = "Delete",
}) => {
  const handleClick = () => {
    if (onClick && typeof onClick === "function") {
      onClick();
    }
  };
  return (
    <Button
      size="sm"
      variant="transparent"
      className={`custom-button-action p-0 ${className ? className : ""}`}
      title={label}
    >
      <div className="d-flex justify-content-center align-items-center">
        <FaTimesCircle
          color={disabled ? "#c4c4c4" : "#f16969"}
          className={`${margin} ${
            disabled ? "text-muted" : "custom-button-delete-icon"
          }`}
          size={iconSize}
          onClick={() => !disabled && handleClick()}
        />
        {title && <span className="ms-2 fw-semibold mt-1">{title}</span>}
      </div>
    </Button>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func,
};

export const RedirectButton = ({ route, title, onClick }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className="p-0 d-flex align-items-center justify-content-center custom-button-action"
      as={Link}
      to={route}
      title={title ?? "Redirect"}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon="external-link-alt"
        className="mb-1 text-primary"
        size="xs"
        cursor={"pointer"}
      />
    </Button>
  );
};

RedirectButton.propTypes = {
  route: PropTypes.string,
};

export const TimerButton = ({ handleTimer, loadingTimer, timerRunning }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className="custom-transperent-common-button p-0 d-flex align-items-center justify-content-center"
      title={timerRunning ? `Stop Timer` : `Start Timer`}
      onClick={handleTimer}
    >
      {loadingTimer ? (
        <Spinner
          animation="border"
          variant={timerRunning ? "danger" : "success"}
          size="sm"
          style={{ width: "1.2rem", height: "1.2rem" }}
        />
      ) : (
        <>
          {timerRunning ? (
            <FaRegStopCircle color="#d94444" size={20} />
          ) : (
            <FaPlayCircle color="#24a324bf" size={20} />
          )}
        </>
      )}
    </Button>
  );
};

export const ReminderButton = ({ handleReminder, id }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className="custom-transperent-common-button p-0 me-1"
      title={`Set Reminder`}
      onClick={() => {
        handleReminder(id);
      }}
    >
      <TbClockBolt color="#44bcd9" size={18} />
    </Button>
  );
};

TimerButton.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  handleTimer: PropTypes.func,
  loadingTimer: PropTypes.bool,
};

export const ViewButton = ({ to, className }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className={`custom-button-action p-0 ${className ? className : ""}`}
      as={Link}
      to={to}
      title="View"
    >
      <FaEye
        color="#7062c9"
        size={16}
        className="custom-button-view-icon m-1"
      />
    </Button>
  );
};

ViewButton.propTypes = {
  to: PropTypes.string,
};

export const TimerActionButton = ({
  margin = "m-1",
  onClick,
  timerStatus,
  className,
  title = "",
  iconSize = 15,
  loading = false,
}) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className={`custom-button-action p-0 ${className ? className : ""}`}
      onClick={onClick}
      title={timerStatus ? "Stop Timer" : "Start Timer"}
      disabled={loading}
    >
      <div className="d-flex justify-content-center align-items-center">
        {timerStatus ? (
          <FaCirclePause
            color={"#f16969"}
            className={`${margin} custom-button-pause-icon`}
            size={iconSize}
          />
        ) : (
          <FaCirclePlay
            color={"#24a324bf"}
            className={`${margin} custom-button-play-icon`}
            size={iconSize}
          />
        )}
        {title && <span className="ms-2 fw-semibold mt-1">{title}</span>}
      </div>
    </Button>
  );
};

export const CloneButton = ({
  onClick,
  className,
  variant,
  size = "sm",
  title = "",
  iconSize = 15,
}) => {
  return (
    <Button
      size={size}
      variant={variant}
      className={`custom-button-action ${className ? className : ""}`}
      onClick={onClick}
      title="Clone"
      style={{ padding: "3.5px 4px" }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <BiCopyAlt size={iconSize} className={`custom-button-clone-icon`} />
        {title && <span className="ms-2 fw-semibold mt-1">{title}</span>}
      </div>
    </Button>
  );
};

export const CustomEditButton = ({
  onClick,
  className,
  label = "",
  buttonText,
  buttonSize = "sm",
  iconSize = 18,
  disabled = false,
}) => {
  return (
    <Button
      variant={"info"}
      size={buttonSize}
      className={className}
      onClick={onClick}
      title={label}
      disabled={disabled}
      style={{ padding: "3px 4.5px" }}
    >
      <span className="d-flex">
        <span>
          <BiEditAlt size={iconSize} />
        </span>
        {buttonText && <span className="ms-2">{buttonText}</span>}
      </span>
    </Button>
  );
};

export const CustomBackButton = ({
  onClick,
  className,
  buttonText = "",
  title,
  iconSize = 20,
}) => {
  return (
    <Button
      className={className}
      variant={"danger"}
      size="sm"
      title={title}
      onClick={onClick}
      style={{ padding: "3px 4px" }}
    >
      <div className="d-flex gap-2 align-items-center">
        <span>
          <IoCloseSharp size={iconSize} />
        </span>
        {buttonText && <span>{buttonText}</span>}
      </div>
    </Button>
  );
};

export const CustomDeleteButton = ({
  onClick,
  className,
  label = "",
  buttonText,
  buttonSize = "sm",
  variant = "falcon-default",
  iconSize = 18,
  disabled = false,
}) => {
  return (
    <Button
      variant={variant}
      size={buttonSize}
      className={className}
      onClick={onClick}
      title={label}
      style={{ padding: "3px 4.5px" }}
      disabled={disabled}
    >
      <span className="d-flex">
        <span>
          <BiTrash size={iconSize} />
        </span>
        {buttonText && <span className="ms-2">{buttonText}</span>}
      </span>
    </Button>
  );
};

export const ConvertButton = ({
  onClick,
  className,
  size = "sm",
  iconSize = 17,
  disabled = false,
  title = "Convert",
}) => {
  return (
    <Button
      size={size}
      variant={"warning"}
      className={`${className ? className : ""}`}
      onClick={onClick}
      title={title}
      disabled={disabled}
    >
      <div className="d-flex justify-content-center align-items-center">
        <IoShuffle size={iconSize} />
        <span className="ms-1">Convert</span>
      </div>
    </Button>
  );
};

export const CustomSearchButton = ({
  onClick,
  className,
  buttonText = "",
  title,
  iconSize = 20,
}) => {
  return (
    <Button
      className={className}
      variant={"info"}
      size="sm"
      title={title}
      onClick={onClick}
      style={{ padding: "3px 4px" }}
    >
      <div className="d-flex gap-2 align-items-center">
        <span>
          <IoSearchSharp size={iconSize} />
        </span>
        {buttonText && <span className="ms-2">{buttonText}</span>}
      </div>
    </Button>
  );
};

export const NotifyButton = ({
  onClick,
  className,
  variant,
  size = "sm",
  iconSize = 15,
  label = "",
  title = "Notify",
}) => {
  return (
    <Button
      size={size}
      variant={variant}
      className={`custom-button-action ${className ? className : ""}`}
      onClick={onClick}
      title={title}
      style={{ padding: "3.5px 4px" }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <TbBellRingingFilled
          color="#b5179e"
          size={iconSize}
          className={`custom-button-notify-icon`}
        />
        {label && <span className="ms-2 fw-semibold mt-1">{label}</span>}
      </div>
    </Button>
  );
};
