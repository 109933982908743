import { AuthWizardContext } from "context/Context";
import React, { useContext } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FaPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { RiErrorWarningLine, RiTimerFlashLine } from "react-icons/ri";

const TimerConfirm = ({
  show,
  handleClose,
  loading,
  handleTimer,
  timerRunningTask,
}) => {
  let { user } = useContext(AuthWizardContext);
  const isTimerRunning = user.running_tasks_count > 0;
  const isTimerActionOnValid =
    isTimerRunning && timerRunningTask === user.running_task_id;
  const modalTitle = isTimerActionOnValid ? "STOP TIMER" : "START TIMER";
  const modalBody = isTimerActionOnValid
    ? "Do you wish to stop the timer for this task?"
    : "Do you wish to start the timer for this task?";
  const iconColor = isTimerActionOnValid ? "#f34848" : "#37b137";
  const closeButtonVariant = "light";

  return (
    <Modal show={show} onHide={handleClose} className="no-border-radius-modal">
      <Modal.Header>
        <Modal.Title className="fs-1 fw-semibold">
          <div className="d-flex align-items-center">
            <RiTimerFlashLine size={25} color={iconColor} className="me-1" />
            <span>{modalTitle}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: isTimerActionOnValid ? "#fdeeeed6" : "#e3ffe3ab",
        }}
      >
        <div className="d-flex align-items-center mt-3 mb-3">
          <RiErrorWarningLine size={20} color={iconColor} className="me-1" />
          <span className="fs-0">{modalBody}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={closeButtonVariant}
          size="sm"
          className="border shadow"
          onClick={handleClose}
          title="Close"
        >
          <IoMdCloseCircle size={23} color="gray" />
        </Button>
        <Button
          variant="transparent"
          size="sm"
          className="shadow border d-flex align-items-center justify-content-center py-1"
          onClick={() => handleTimer()}
          title={isTimerActionOnValid ? "Stop Timer" : "Start Timer"}
          disabled={!show || loading}
        >
          {loading ? (
            <Spinner
              size="sm"
              animation="border"
              variant={isTimerActionOnValid ? "danger" : "success"}
              style={{ width: "1.3rem", height: "1.3rem" }}
            />
          ) : (
            <>
              {isTimerActionOnValid ? (
                <FaRegStopCircle size={23} color={iconColor} />
              ) : (
                <FaPlayCircle size={23} color={iconColor} />
              )}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TimerConfirm;
