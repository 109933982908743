import { AuthWizardContext } from "context/Context";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import React, { useContext, useState } from "react";
import { Row } from "react-bootstrap";
import { BiObjectsHorizontalLeft } from "react-icons/bi";
import { createSearchParams, useNavigate } from "react-router-dom";
import TaskForm from "module/Task/TaskForm/TaskForm";
import PropTypes from "prop-types";
import StatusCarousel from "../StatusCarousel/StatusCarousel";

function TaskSummary({ taskSummary, taskSummaryLoading }) {
  const navigate = useNavigate();
  const { user } = useContext(AuthWizardContext);
  const [showTaskForm, setShowTaskForm] = useState(false);

  const statusArray =
    taskSummary?.status_info?.length > 0
      ? taskSummary?.status_info?.map((item) => ({
          key: item?.status,
          value: item?.count,
          label: getTaskStatusWithProps(item?.status, "label"),
          icon: getTaskStatusWithProps(item?.status, "icon"),
        }))
      : [];

  const totalCount = {
    key: "total_count",
    value: taskSummary?.total_no_of_task ?? 0,
    label: "Total Tasks",
    icon: <BiObjectsHorizontalLeft color={"#00a9fe"} size={25} />,
  };

  const permission = {
    store: GetAcodaxPermission("TASK", "create"),
    show: GetAcodaxPermission("TASK", "show"),
  };

  const handleAction = (e, action, data) => {
    e.stopPropagation();
    if (action === "navigation") {
      if (data?.key === "total_count") {
        if (permission?.show) {
          navigate(
            `/tasks/list?${createSearchParams({
              assignee: user?.id,
              assignee_ref: JSON.stringify({
                value: user?.id,
                label: user?.name,
              }),
            })}`
          );
        }
      } else {
        if (permission?.show) {
          navigate(
            `/tasks/list?${createSearchParams({
              status_filter: [`${data?.key}`],
              status_filter_ref: JSON.stringify([
                {
                  value: data?.key,
                  label: data?.key?.replace("_", " "),
                },
              ]),
              assignee: user?.id,
              assignee_ref: JSON.stringify({
                value: user?.id,
                label: user?.name,
              }),
            })}`
          );
        }
      }
    } else if (action === "store") {
      if (permission?.store) {
        setShowTaskForm(true);
      }
    }
  };

  return (
    <>
      <Row>
        <StatusCarousel
          statusArray={statusArray}
          totalCount={totalCount}
          loading={taskSummaryLoading}
          loadingBox={6}
          permission={permission}
          countLabel="Tasks"
          handleAction={handleAction}
          store
        />
      </Row>
      {showTaskForm && (
        <TaskForm
          show={showTaskForm}
          handleCloseForm={() => setShowTaskForm(false)}
          openFrom="quick_link"
        />
      )}
    </>
  );
}

TaskSummary.propTypes = {
  taskSummary: PropTypes.any,
  taskSummaryLoading: PropTypes.bool,
};

export default TaskSummary;
