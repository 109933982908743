import React from "react";
import PropTypes from "prop-types";
import Flex from "components/common/Flex";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const TabTitle = ({ title, value, percentage }) => {
  const Translate = useAxisproTranslate();
  return (
    <div className="p-2 pe-2 text-start cursor-default">
      <h6 className="text-800 fs--2 text-nowrap">{Translate(title)}</h6>
      <h5 className="fw-bold fs-1">{value}</h5>
      <Flex alignItems="center">
        <h6
          className="fs--2 mb-0 ms-2 
             text-success"
        >
          {percentage}%
        </h6>
      </Flex>
    </div>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  progress: PropTypes.bool,
};

export default TabTitle;
