import { React, useState } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";
import { v4 as uuidv4 } from "uuid";
import { FormCheck } from "react-bootstrap";

const SelectProject = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  clientId,
  renderFrom,
  onFocus,
  disabled = false,
  clearable = true,
  isIncludeCancelled = true,
}) => {
  const [includeCancelled, setIncludeCancelled] = useState(false);
  const uniqueId = uuidv4();

  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: "crm/project-autocomplete",
      setParams: (searchKey) => ({
        title: searchKey,
        customer_id: clientId,
        include_cancelled_projects: includeCancelled ? 1 : 0,
      }),
      setOptions: (option) => setOptionData(option),
      onFocus: onFocus,
      dependencyKeyToResetDefaultOptions: `${clientId}${
        includeCancelled ? "1" : "00"
      }`,
    });

  const setOptionData = (option) => ({
    label: option.title,
    value: option.id,
  });

  return (
    <>
      <div className="position-relative">
        <AsyncSelect
          cacheOptions
          loadOptions={fetchData}
          defaultOptions={defaultOptions}
          onFocus={setDefaultResult}
          isLoading={loading}
          isClearable={clearable}
          isDisabled={disabled}
          name={name ? name : "project"}
          value={value}
          placeholder={placeholder ?? ""}
          onChange={handleFieldChange}
          className={`custom-select ${
            error
              ? "form-control ps-0 py-0 is-invalid text-capitalize"
              : "text-capitalize"
          }`}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              fontSize: "13px",
              borderRadius: "0px",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isFocused
                ? state.isSelected
                  ? "#192E49"
                  : "#e1e2ea"
                : state.isSelected
                ? "#696c96"
                : "inherit",
              "&:hover": {
                backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
              },
            }),
            control: (provided) => ({
              ...provided,
              ...style,
            }),
          }}
        />
        {isIncludeCancelled && (
          <FormCheck
            type="checkbox"
            id={`includeCancelled-${name ? name : "project"}-${uniqueId}`}
            label="Cancelled"
            title="Include cancelled project"
            checked={includeCancelled}
            onChange={(e) => setIncludeCancelled(e.target.checked)}
            className="auto-complete-checkbox"
          />
        )}
      </div>
    </>
  );
};

SelectProject.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
};

export default SelectProject;
