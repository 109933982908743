import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "./GitLog.scss";

function GitLogLoader({ height = "30rem", items = 10 }) {
  const [loadingIndex, setLoadingIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingIndex((prev) => prev + 1);
    }, 300);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Row>
        <div
          className="widget-wrap "
          style={{ maxHeight: height, overflow: "hidden", overflowY: "scroll" }}
        >
          {[...Array(items).keys()].map((index) => (
            <div
              className={`git-graph-container-div-skelton ${
                loadingIndex > index ? "visible" : ""
              }`}
              key={index}
            >
              <div className="git-graph-container-wrapper-skelton d-flex justify-content-between align-item-center">
                <div className="w-100">
                  <span className="d-block pt-1">
                    <p className="d-inline ps-2">
                      <span
                        style={{
                          height: "1rem",
                          width: "10%",
                          display: "inline-block",
                          backgroundColor: "#D0D0D0",
                          borderRadius: "3px",
                          marginRight: "10px",
                        }}
                      >
                        &nbsp;
                      </span>
                      <span
                        style={{
                          height: "1rem",
                          width: "80%",
                          display: "inline-block",
                          backgroundColor: "#D0D0D0",
                          borderRadius: "3px",
                        }}
                      >
                        &nbsp;
                      </span>
                    </p>
                  </span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                  <span className="custom-text-muted">
                    <span style={{ width: "6rem", display: "inline-block" }}>
                      &nbsp;
                    </span>
                  </span>{" "}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Row>
    </div>
  );
}

export default GitLogLoader;
