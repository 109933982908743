import React from "react";
import PropTypes from "prop-types";
import { Badge, Card } from "react-bootstrap";
import Flex from "components/common/Flex";
import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { CanvasRenderer } from "echarts/renderers";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  GaugeChart,
  CanvasRenderer,
]);

const CompletedTaskTile = ({ data, isLoading }) => {
  const Translate = useAxisproTranslate();
  const getOptions = () => ({
    series: [
      {
        type: "gauge",
        startAngle: 90,
        endAngle: -270,
        radius: "100%",
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: "#5795f6",
          },
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, "#dae2ee"]],
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        data: [
          {
            value: data.completed_task_percentage,
            detail: {
              offsetCenter: ["7%", "4%"],
            },
          },
        ],
        detail: {
          width: 50,
          height: 14,
          fontSize: 15,
          fontWeight: 500,
          fontFamily: "poppins",
          color: "#748194",
          formatter: "{value}%",
        },
      },
    ],
  });

  return (
    <>
      {isLoading ? (
        <Card className="h-100 p-3 py-4 card-main">
          <DashboardTileLoader column={1} />
        </Card>
      ) : (
        <Card className="h-100 py-3 card-main">
          <Card.Body
            as={Flex}
            justifyContent="around"
            alignItems="center"
            className="py-0"
          >
            <div>
              <div className="mb-3">
                <h6
                  className="mb-3 flex-1 fw-bold fs-0 p-0"
                  style={{ width: "max-content" }}
                >
                  {Translate("Completed Tasks")}
                </h6>
              </div>
              {Object.keys(data).length > 0 ? (
                <div className="d-flex justify-content-between align-items-start">
                  <div>
                    <h2 className="fw-medium text-600 fs-4">
                      {data.completed_task_count}
                    </h2>
                  </div>
                  <Badge
                    pill
                    bg="transparent"
                    className={`text-success fs-10 px-2`}
                  >
                    <FontAwesomeIcon
                      icon={
                        parseFloat(data?.completed_task_percentage) === 0
                          ? null
                          : parseFloat(data?.completed_task_percentage) < 0
                          ? "caret-down"
                          : "caret-up"
                      }
                      className="me-1"
                    />
                    {data?.completed_task_percentage}%
                  </Badge>
                </div>
              ) : (
                <div className="mt-3">
                  <NothingToShow />
                </div>
              )}
            </div>
            {Object.keys(data).length > 0 && (
              <div className="ps-0">
                <ReactEChartsCore
                  echarts={echarts}
                  option={getOptions("#2c7be5", 30)}
                  style={{ height: "5.6rem", width: "8rem" }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

CompletedTaskTile.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CompletedTaskTile;
