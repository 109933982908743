import { React, useCallback, useContext, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import CrmStats from "./CrmStats/CrmStats";
import DealStorageFunnel from "./DealStorageFunnel/DealStorageFunnel";
import UpcomingSchedule from "./UpcomingSchedule/UpcomingSchedule";
import TotalCustomerRevenueTile from "./TotalCustomerRevenueTile/TotalCustomerRevenueTile";
import TodoListTile from "./TodoListTile/TodoListTile";
import RevenueForecast from "./RevenueForecast/RevenueForecast";
import EventCalendar from "./EventCalendar/EventCalendar";
import RecentActivityTile from "./RecentActivities/RecentActivityTile";
import { recentActivities } from "./RecentActivities/RecentActivityData";
import Stats from "./Stats/Stats";
import TotalTaskTile from "./TotalTaskTile/TotalTaskTile";
import { totalTask } from "./TotalTaskTile/TotalTaskTileData";
import RunningTaskTile from "./RunningTaskTile/RunningTaskTile";
import CompletedTaskTile from "./CompletedTaskTile/CompletedTaskTile";
import RunningProjects from "./RunningProjects/RunningProjects";
import TicketStatus from "./TicketStatus/TicketStatus";
import SalesPipeLine from "./SalesPipeline/SalesPipeLine";
import { apiCall } from "helpers/apiCalls";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import useAxisproPermission from "hooks/useAxisproPermission";
import TaskSummary from "./TaskSummary/TaskSummary";
import CriticalTasks from "./CriticalTasks/CriticalTasks";
import { AuthWizardContext } from "context/Context";
import TaskSummaryTilesGroup from "./TaskSummary/TaskSummaryTilesGroup";
import TicketSummary from "./TicketSummary/TicketSummary";
// import TicketSummaryTilesGroup from "./TicketSummary/TicketSummaryTilesGroup";
import CriticalTickets from "./CriticalTickets/CriticalTickets";
import TicketSummaryTilesGroup from "./TicketSummary/TicketSummaryTilesGroup";

const Dashboard = () => {
  const { user } = useContext(AuthWizardContext);
  const userId = user?.id ?? "";
  const [isLoading, setIsLoading] = useState(false);
  const [upcomingScheduleLoading, setUpcomingScheduleLoading] = useState(false);
  const [data, setData] = useState({});
  const [upcomingSchedules, setUpcomingSchedules] = useState([]);
  const [refreshUpcomingSchedule, setRefreshUpcomingSchedule] = useState(false);
  const [taskSummary, setTaskSummary] = useState({});
  const [taskSummaryLoading, setTaskSummaryLoading] = useState(false);
  const [ticketSummary, setTicketSummary] = useState({});
  const [ticketSummaryLoading, setTicketSummaryLoading] = useState(false);
  const axisProPermission = useAxisproPermission();
  const permission = useMemo(
    () => ({
      listLeadOrOpportunity:
        GetAcodaxPermission("LEAD", "list") ||
        GetAcodaxPermission("OPPORTUNITY", "list"),
      listLead: GetAcodaxPermission("LEAD", "list"),
      listOpportunity: GetAcodaxPermission("OPPORTUNITY", "list"),
      showUser: GetAcodaxPermission("USER", "show"),
      showAccount: GetAcodaxPermission("ACCOUNT", "show"),
      listTodo: GetAcodaxPermission("TODO", "list"),
      listLeadAndOpportunity:
        GetAcodaxPermission("LEAD", "list") &&
        GetAcodaxPermission("OPPORTUNITY", "list"),
      listProjectOrTask:
        GetAcodaxPermission("PROJECT", "list") ||
        GetAcodaxPermission("TASK", "show"),
      showTask:
        GetAcodaxPermission("TASK", "show") ||
        GetAcodaxPermission("CT", "view") ||
        GetAcodaxPermission("AT", "view"),
      listProjectDetailedReport: axisProPermission("project-detailed-report"),
      listTickets: GetAcodaxPermission("TICKET", "list"),
    }),
    [axisProPermission]
  );

  const fetchData = useCallback(async () => {
    if (
      permission.showAccount ||
      permission.listProjectOrTask ||
      permission.showTask ||
      permission.listTickets
    ) {
      try {
        setIsLoading(true);
        const response = await apiCall({ url: "crm/dashboard-data" });
        setData(response);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    permission.listProjectOrTask,
    permission.listTickets,
    permission.showAccount,
    permission.showTask,
  ]);

  const fetchUpcomingSchedules = useCallback(async () => {
    if (permission.listLeadOrOpportunity) {
      try {
        setUpcomingScheduleLoading(true);
        const response = await apiCall({
          url: "crm/dashboard/upcoming-schedules",
        });
        if (response) {
          let upComingSchedule = [
            ...response?.meetings.map((meeting) => ({
              ...meeting,
              module: "Meeting",
            })),
            ...response?.calls?.map((call) => ({ ...call, module: "Calling" })),
          ];
          if (upComingSchedule?.length > 0) {
            setUpcomingSchedules(upComingSchedule);
          } else {
            setUpcomingSchedules([]);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setUpcomingScheduleLoading(false);
      }
    }
  }, [permission.listLeadOrOpportunity]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchUpcomingSchedules();
  }, [fetchUpcomingSchedules, refreshUpcomingSchedule]);

  useEffect(() => {
    const getData = async () => {
      setTaskSummaryLoading(true);
      try {
        const resData = await apiCall({
          url: "crm/task-summary",
          params: {
            employee_id: userId,
          },
        });
        setTaskSummary(resData);
      } catch (err) {
        console.log(err);
      } finally {
        setTaskSummaryLoading(false);
      }
    };

    if (userId && permission.showTask) getData();
  }, [userId, permission.showTask]);

  useEffect(() => {
    const getData = async () => {
      setTicketSummaryLoading(true);
      try {
        const resData = await apiCall({
          url: "crm/ticket-summary",
          params: {
            user_dashboard: 1,
          },
        });

        setTicketSummary(resData);
      } catch (err) {
        console.log(err);
      } finally {
        setTicketSummaryLoading(false);
      }
    };

    if (permission.listTickets) getData();
  }, [permission.listTickets]);

  const onlyHaveShowTaskAndListProjectOrTaskPermission = useMemo(() => {
    const otherPermissions = [
      "listLeadOrOpportunity",
      "listLead",
      "listOpportunity",
      "showUser",
      "showAccount",
      "listTodo",
      "listLeadAndOpportunity",
      "listTickets",
      "listProjectDetailedReport",
    ];

    const allOtherPermissionsFalse = otherPermissions.every(
      (permissionKey) => !permission[permissionKey]
    );

    return (
      permission.showTask &&
      permission.listProjectOrTask &&
      allOtherPermissionsFalse
    );
  }, [permission]);

  return (
    <div className="pb-3 d-flex flex-column gap-3">
      <Row className="g-3">
        {(permission.listLeadOrOpportunity ||
          permission.listLead ||
          permission.listOpportunity ||
          permission.showUser) && (
          <Col xxl={9} xl={12}>
            <CrmStats />
          </Col>
        )}
        {permission.listLeadOrOpportunity && (
          <Col xxl={3} className="d-none d-xxl-block">
            <UpcomingSchedule
              data={upcomingSchedules?.[0]}
              isLoading={upcomingScheduleLoading}
            />
          </Col>
        )}
        {permission.listLeadOrOpportunity && (
          <Col
            sm={12}
            className="d-none d-xxl-none d-md-none d-lg-none d-xl-none d-sm-block"
          >
            <UpcomingSchedule
              data={upcomingSchedules?.[0]}
              isLoading={upcomingScheduleLoading}
            />
          </Col>
        )}
        {permission.listLeadOrOpportunity && (
          <Col
            xs={12}
            className="d-block d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none"
          >
            <UpcomingSchedule
              data={upcomingSchedules?.[0]}
              isLoading={upcomingScheduleLoading}
            />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listLeadOrOpportunity && (
          <Col xl={3} lg={3} className="d-none d-xxl-none d-lg-block">
            <UpcomingSchedule
              data={upcomingSchedules?.[0]}
              isLoading={upcomingScheduleLoading}
            />
          </Col>
        )}
        {permission.listLead && (
          <Col xxl={4} xl={5} lg={5} md={6}>
            <SalesPipeLine />
          </Col>
        )}
        {permission.listOpportunity && (
          <Col xxl={3} xl={4} lg={4} md={6}>
            <DealStorageFunnel />
          </Col>
        )}
        {permission.listLeadOrOpportunity && (
          <Col md={4} className="d-none d-xxl-none d-md-block d-lg-none">
            <UpcomingSchedule
              data={upcomingSchedules?.[0]}
              isLoading={upcomingScheduleLoading}
            />
          </Col>
        )}
        {permission.showAccount && (
          <Col xxl={2} xl={4} lg={4} md={4}>
            <TotalCustomerRevenueTile data={data} isLoading={isLoading} />
          </Col>
        )}
        {permission.listTodo && (
          <Col xxl={3} xl={8} lg={8} md={4}>
            <TodoListTile />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listLeadAndOpportunity && (
          <Col xxl={4} xl={12}>
            <RevenueForecast />
          </Col>
        )}
        {permission.listTodo && (
          <Col xxl={5} xl={7} lg={7} md={7}>
            <EventCalendar
              setRefreshUpcomingSchedule={setRefreshUpcomingSchedule}
              data={upcomingSchedules}
              isLoading={upcomingScheduleLoading}
            />
          </Col>
        )}
        {!onlyHaveShowTaskAndListProjectOrTaskPermission && (
          <Col xxl={3} xl={5} lg={5} md={5}>
            <RecentActivityTile data={recentActivities} />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listProjectOrTask && (
          <Col md={12} lg={12} xl={12} xxl={5}>
            <Stats data={data} isLoading={isLoading} />
          </Col>
        )}
        {permission.showTask && (
          <Col md={4} lg={4} xl={4} xxl={2}>
            <TotalTaskTile data={totalTask} item={data} isLoading={isLoading} />
          </Col>
        )}
        {permission.showTask && (
          <Col md={4} lg={4} xl={4} xxl={2}>
            <RunningTaskTile data={data} isLoading={isLoading} />
          </Col>
        )}
        {permission.showTask && (
          <Col md={4} lg={4} xl={4} xxl={3}>
            <CompletedTaskTile data={data} isLoading={isLoading} />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listProjectDetailedReport && (
          <Col xxl={9} xl={8} lg={7} md={7}>
            <RunningProjects />
          </Col>
        )}
        {permission.listTickets && (
          <Col xxl={3} xl={4} lg={5} md={5}>
            <TicketStatus data={data} isLoading={isLoading} />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.showTask && (
          <Col xxl={12} xl={12}>
            <TaskSummary
              taskSummary={taskSummary}
              taskSummaryLoading={taskSummaryLoading}
            />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.showTask && (
          <Col xxl={3} xl={4} lg={4} md={12}>
            <TaskSummaryTilesGroup
              taskSummary={taskSummary}
              taskSummaryLoading={taskSummaryLoading}
            />
          </Col>
        )}
        {permission.showTask && (
          <Col xxl={9} xl={8} lg={8} md={12}>
            <CriticalTasks />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listTickets && (
          <Col xxl={12} xl={12}>
            <TicketSummary
              ticketSummary={ticketSummary}
              ticketSummaryLoading={ticketSummaryLoading}
            />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {permission.listTickets && (
          <Col xxl={10} xl={9} lg={9} md={12}>
            <CriticalTickets />
          </Col>
        )}
        {permission.listTickets && (
          <Col xxl={2} xl={3} lg={3} md={12}>
            <TicketSummaryTilesGroup
              ticketSummary={ticketSummary}
              ticketSummaryLoading={ticketSummaryLoading}
            />
          </Col>
        )}
      </Row>
      <Row className="g-3">
        {onlyHaveShowTaskAndListProjectOrTaskPermission && (
          <Col xxl={3} xl={5} lg={5} md={5}>
            <RecentActivityTile data={recentActivities} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
