import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import {
  Card,
  Col,
  Offcanvas,
  Row,
  Tab,
  Tabs,
  Image,
  Alert,
  Badge,
} from "react-bootstrap";
import {
  FaBell,
  FaGitAlt,
  FaHashtag,
  FaRegUserCircle,
  FaUserAltSlash,
} from "react-icons/fa";
import { BiComment, BiSupport, BiTask } from "react-icons/bi";
import { AuthWizardContext, StoreContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import { tasksFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import {
  CloneButton,
  CustomBackButton,
  CustomDeleteButton,
  CustomEditButton,
  NotifyButton,
  RedirectButton,
} from "module/common/Buttons/AppButtons";
import ActivityTimeLine from "../../common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";
import Collaborator from "../../common/ViewPages/Common/Collaborator/Collaborator";
import DigitalTimer from "module/common/Timer/DigitalTimer";
import ProfileImage from "assets/img/Avathar/profile.png";
import ShowHtmlContent from "components/common/showHtmlContent/ShowHtmlContent";
import TimeSheetLog from "module/common/ViewPages/Common/TimeSheet/TimeSheetLog";
import Comments from "module/common/ViewPages/Common/Comments/Comments";
import { Link } from "react-router-dom";
import ListLoading from "module/common/ListLoading";
import TabLoading from "module/common/TabLoading";
import ImagePreview from "module/common/ImagePreview/ImagePreview";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import TimerConfirm from "module/common/Timer/TimerConfirm";
import Reminders from "module/common/ViewPages/Common/Reminders/Reminders";
import DeleteWarning from "module/common/Warnings/DeleteWarning";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import CommonAttachments from "module/common/ViewPages/Common/Attachments/CommonAttachments";
import TicketInfo from "module/Tickets/TicketInfo/TicketInfo";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { stringLimiter } from "helpers/utils";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import { AiOutlineCodeSandbox, AiOutlineNodeCollapse } from "react-icons/ai";
import { BsCalendar2Range } from "react-icons/bs";
import { TbClockBolt, TbPackage } from "react-icons/tb";
import {
  MdAttachFile,
  MdDateRange,
  MdOutlineDateRange,
  MdOutlineNoteAlt,
} from "react-icons/md";
import { RxActivityLog, RxLapTimer } from "react-icons/rx";
import { GrDocumentTime } from "react-icons/gr";
import UpdateAssigneeListDropDown from "components/common/DropDown/UpdateAssigneeListDropDown";
import CommonStatusUpdateDropDown from "components/common/DropDown/CommonStatusUpdateDropDown";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import GitLog from "../Components/GitLog";

const TaskInfo = ({
  taskInfoId,
  showTaskInfo,
  handleCloseTaskInfo,
  getData,
  afterAssigneeChange,
  afterStatusChange,
  updateTimerStatus,
  afterDelete,
}) => {
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [infoData, setInfoData] = useState({});
  const { itemId, itemInfoId, client_id, empId, overviewId } = useParams();
  const [dataProcessing, setDataProcessing] = useState(false);
  const [statusUpdateFormData, setStatusUpdateFormData] =
    useState(tasksFormKeys);
  const [collabList, setCollabList] = useState({});
  const { dispatch, user } = useContext(AuthWizardContext);
  const [hasMatch, setHasMatch] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState(false);
  const [show, setShow] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const location = useLocation();
  const renderFrom = location.pathname.split("/")[1];
  const commonSearchParams = createSearchParams({ ...allQueryParams });
  const [notifyLoading, setNotifyLoading] = useState();
  const [ticketInfo, setTicketInfo] = useState({
    show: false,
    id: "",
  });
  const permission = {
    store: GetAcodaxPermission("TASK", "create"),
    update: GetAcodaxPermission("TASK", "update"),
    delete: GetAcodaxPermission("TASK", "delete"),
    updateTaskAssignee: GetAcodaxPermission("TA", "update"),
    showTicket: GetAcodaxPermission("TICKET", "update"),
  };
  const { addIitemToStore } = useContext(StoreContext);

  const activeTab = queryParams.get("info_tab") || "comments";
  const activeRoute =
    renderFrom === "tasks"
      ? `/tasks/list`
      : renderFrom === "sprints"
      ? `/sprints/info/${itemInfoId}`
      : renderFrom === "account"
      ? `/account/overview/${client_id}`
      : renderFrom === "employee"
      ? `/employee/overview/${empId}`
      : renderFrom === "tickets"
      ? location.pathname === "/tickets/list"
        ? location.pathname
        : `/tickets/list/overview/${overviewId}`
      : `/project/info/${itemInfoId}`;
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const handleDeleteClose = (status) => {
    setDeleteModal(false);
    if (status !== "error") {
      handleCloseTaskInfo();
    }
  };

  const handleClose = () => setShow(false);
  const handleTimerSection = (data) => {
    if (data?.running_status === true) {
      setShow(true);
    } else {
      handleTimer();
    }
  };

  useEffect(() => {
    if (showTaskInfo) {
      getTaskData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTaskInfo]);

  const getTaskData = async () => {
    setDataProcessing(true);
    try {
      const data = await apiCall({
        url: `crm/task/${taskInfoId ? taskInfoId : itemId}`,
      });
      setInfoData(data);
      if (data?.collaborators?.length > 0) {
        setCollabList(data?.collaborators);
      }
      setStatusUpdateFormData({
        ...statusUpdateFormData,
        project_id: data.project_id,
        title: data.title,
        description: data.description,
        start_date: data.start_date,
        end_date: data.end_date,
        assigned_to: data.assigned_to,
        collaborators: data.collaborators.map((item) => ({ id: item.user_id })),
        status: "done",
        labels: data.labels.map((item) => ({ id: item.id })),
      });
    } catch (error) {
      console.error("Error fetching email data:", error);
    } finally {
      setDataProcessing(false);
    }
  };

  const handleTimer = async () => {
    if (loadingTimer) return;
    setLoadingTimer(true);

    try {
      const response = await axios.post(
        Object.keys(infoData.current_time_tracker).length > 0
          ? `crm/stop-timer/${itemId || taskInfoId}`
          : `crm/start-timer/${itemId || taskInfoId}`
      );

      if (response.data.success === true) {
        // const res = await axios.get("crm/running-tasks-info");
        // if (res.data.success === true) {
        //   const taskDetails = res.data.data;
        //   let activeTaskId = "";
        //   taskDetails?.tasks?.forEach((item) => {
        //     activeTaskId = item.id;
        //   });
        const updatedData = {
          ...user,
          running_tasks_count:
            Object.keys(infoData.current_time_tracker).length > 0 ? 0 : 1,
          running_task_id:
            Object.keys(infoData.current_time_tracker).length > 0
              ? ""
              : infoData?.id,
          running_task_reference_count:
            Object.keys(infoData.current_time_tracker).length === 0
              ? user?.running_task_reference_count
                ? user?.running_task_reference_count + 1
                : 1
              : user?.running_task_reference_count
              ? user?.running_task_reference_count + 1
              : 2,
        };
        dispatch({ type: "UPDATE", payload: { ...updatedData } });
        if (Object.keys(infoData?.current_time_tracker).length > 0) {
          localStorage.removeItem("runningTaskData");
          addIitemToStore("runningTaskData", {});
        }
        setShow(false);
        setInfoData((prevState) => ({
          ...prevState,
          running_status:
            Object.keys(infoData.current_time_tracker).length > 0
              ? false
              : true,
        }));
        if (updateTimerStatus && typeof updateTimerStatus === "function") {
          updateTimerStatus(
            infoData?.id,
            Object.keys(infoData.current_time_tracker).length > 0
              ? "stop"
              : "start"
          );
        }
        if (afterStatusChange && typeof afterStatusChange === "function") {
          afterStatusChange("in_progress", infoData?.id);
        }
        // setTimeout(() => {
        //   setShow(false);
        // }, 500);
        // }
        //  else {
        //   handleRequestError();
        // }

        if (response.data.data.end_time) {
          updateInfoData(response.data.data, true);
        } else {
          updateInfoData(response.data.data, false);
        }
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoadingTimer(false);
    }
  };

  const updateInfoData = (data, hasEndTime) => {
    setInfoData((prevData) => ({
      ...prevData,
      total_time_tracked: data.task.total_time_tracked,
      current_time_tracker: hasEndTime
        ? []
        : [...prevData.current_time_tracker, data],
      time_trackers: hasEndTime
        ? prevData.time_trackers.map((tracker) => {
            if (tracker.id === data.id) {
              tracker.end_time = data.end_time;
              tracker.end_time_formatted = data.end_time_formatted;
              tracker.time_tracked = data.time_tracked;
            }
            return tracker;
          })
        : [...prevData.time_trackers, data],
      status: data.task.status,
      status_formatted: data.task.status_formatted,
    }));
  };

  const handleRequestError = (error) => {
    if (error && error.response.data.message) {
      const validationError = error.response.data.data?.errors;
      showToast(validationError.task_id[0]);
    } else {
      showToast(
        "Something went wrong, please refresh the page and try again.",
        "error"
      );
    }
  };

  useLayoutEffect(() => {
    let assigneeMatch = false;
    let colabMatch = false;
    if (infoData.assigned_to) {
      assigneeMatch = infoData.assigned_to === user.id;
    }
    if (Object.keys(collabList).length > 0) {
      colabMatch = collabList.some((collab) => collab.user_id === user.id);
    }
    setHasMatch(assigneeMatch || colabMatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collabList, infoData.assigned_to]);

  const handleImageClick = (event) => {
    const clickedImageUrl = event.target.src;
    setPreviewImage(clickedImageUrl);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage(null);
  };

  const handleClone = (id) => {
    handleCloseTaskInfo();
    navigate(
      `${activeRoute}${
        renderFrom === "tasks"
          ? `/clone/${itemId || taskInfoId}`
          : renderFrom === "sprints"
          ? `/task/clone/${itemId || taskInfoId}`
          : renderFrom === "tickets"
          ? `/task/clone/${itemId || taskInfoId}`
          : `/task/clone/${itemId || taskInfoId}`
      }?${createSearchParams(allQueryParams)}`
    );
  };

  const handleTicketInfo = (data) => {
    if (permission?.showTicket) {
      setTicketInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const statusChange = (status) => {
    if (status) {
      let formattedStatus = status
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      setInfoData((prevState) => ({
        ...prevState,
        status: status,
        status_formatted: formattedStatus,
      }));
    }
  };

  const afterAssigneeUpdate = (data) => {
    setInfoData((prevData) => ({
      ...prevData,
      assignee: data?.assignee,
      assigned_to: data?.assigned_to,
      assignee_full_name: data?.assignee_full_name,
      assignee_profile: data?.assignee_profile,
    }));

    let assigneeDetails = {
      label: data?.assignee_full_name ?? "",
      value: data?.assigned_to ?? "",
      image: data?.assignee_profile,
    };
    if (afterAssigneeChange && typeof afterAssigneeChange === "function") {
      afterAssigneeChange(data?.id, assigneeDetails, data);
    }
  };

  const handleAfterDelete = (id) => {
    if (afterDelete && typeof afterDelete === "function") {
      afterDelete(id);
    }
  };

  const hasPermissionToNotify = (
    loggedInUserId,
    createdUserId,
    assignedUserId,
    taskId,
    status
  ) => {
    if (!permission.store) {
      return false;
    }

    if (status === "done") {
      return false;
    }

    const notifiedTasksFromLocalStorage =
      JSON.parse(localStorage.getItem("notifiedTasks")) || [];

    const taskAlreadyNotified = notifiedTasksFromLocalStorage.some(
      (notification) => notification.data === taskId
    );

    if (taskAlreadyNotified) {
      return false;
    }

    return (
      loggedInUserId === createdUserId && loggedInUserId !== assignedUserId
    );
  };

  const handleNotify = (data) => {
    let notifiedTasksFromLocalStorage =
      JSON.parse(localStorage.getItem("notifiedTasks")) || [];

    const taskAlreadyNotified = notifiedTasksFromLocalStorage.some(
      (notification) => notification.data === data?.id
    );

    if (taskAlreadyNotified) {
      showToast("Already notified", "warning");
      return;
    }

    if (!loadingTimer) {
      setNotifyLoading(true);

      axios({
        method: "post",
        url: `crm/notify-task-assignee`,
        data: {
          task_id: data?.id,
          assignee: data?.assigned_to,
        },
      })
        .then(() => {
          showToast("Notification sent successfully", "success");

          const timeStamp = Date.now();
          const newNotification = {
            id: timeStamp,
            data: data?.id,
          };

          notifiedTasksFromLocalStorage.push(newNotification);
          localStorage.setItem(
            "notifiedTasks",
            JSON.stringify(notifiedTasksFromLocalStorage)
          );
        })
        .catch((error) => {
          showToast(error?.response?.data?.message, "error");
        })
        .finally(() => {
          setNotifyLoading(false);
        });
    }
  };

  return (
    <>
      <Offcanvas style={{ width: "700px" }} show={showTaskInfo} placement="end">
        <>
          <Offcanvas.Header className="border-bottom">
            <Offcanvas.Title className="d-flex flex-wrap justify-content-between align-items-center gap-2 w-100">
              <div className="d-flex align-items-center justify-content-between gap-2">
                <BiTask size={23} />
                <span className="fs-1">{Translate("Task Overview")}</span>
                {!dataProcessing && (
                  <div className="me-2">
                    <RedirectButton
                      route={`/tasks/list/overview/${itemId || taskInfoId}`}
                    />
                  </div>
                )}
              </div>
              <div className="d-flex flex-wrap align-items-center justify-content-start gap-2">
                {!dataProcessing && permission?.store && (
                  <CloneButton
                    variant="light"
                    size="sm"
                    className="shadow pt-1 pb-1"
                    iconSize={20}
                    onClick={() => handleClone(infoData.id)}
                  />
                )}
                {!dataProcessing && permission?.update && (
                  <CustomEditButton
                    variant="light"
                    onClick={() => {
                      handleCloseTaskInfo();
                      navigate(
                        renderFrom === "tasks"
                          ? `${activeRoute}/edit/${
                              itemId || taskInfoId
                            }?${commonSearchParams}`
                          : renderFrom === "sprints"
                          ? `${activeRoute}/task/edit/${
                              itemId || taskInfoId
                            }?${commonSearchParams}`
                          : renderFrom === "tickets"
                          ? `${activeRoute}/task/edit/${
                              itemId || taskInfoId
                            }?${commonSearchParams}`
                          : `${activeRoute}/task/edit/${
                              itemId || taskInfoId
                            }?${commonSearchParams}`
                      );
                    }}
                    label="Edit Task"
                    className="shadow"
                    disabled={
                      infoData?.running_status || loadingTimer || dataProcessing
                    }
                  />
                )}
                {!dataProcessing &&
                  permission?.delete &&
                  getData &&
                  typeof getData === "function" && (
                    <CustomDeleteButton
                      variant="light"
                      title="Back"
                      onClick={() => handleDelete(true)}
                      className={"shadow"}
                      disabled={
                        infoData?.running_status ||
                        loadingTimer ||
                        dataProcessing
                      }
                      label="Delete Task"
                    />
                  )}
                <CustomBackButton
                  variant="light"
                  title="Back"
                  onClick={handleCloseTaskInfo}
                  className={"shadow"}
                />
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Header
            className="border-bottom p-0"
            style={{
              backgroundColor: "#384251b5",
              color: "whitesmoke",
            }}
          >
            <Row className="m-0 flex-fill">
              {!dataProcessing ? (
                <>
                  <Col md={2} sm={2} xs={2} className="border-end">
                    <div className="task-custom-table-header-div-crm d-flex align-items-center">
                      <span className="mb-1">
                        <FaHashtag size={18} />
                      </span>
                      <span className="fs-1">{infoData.task_number}</span>
                    </div>
                  </Col>
                  <Col
                    md={4}
                    sm={4}
                    xs={4}
                    className="border-end d-flex align-items-center justify-content-center"
                  >
                    {infoData.assigned_to ? (
                      <div className="task-custom-table-header-div-crm align-items-center">
                        <Image
                          className="me-1"
                          src={
                            infoData.assigned_to && infoData.assignee_profile
                              ? infoData.assignee_profile
                              : ProfileImage
                          }
                          roundedCircle
                          alt="?"
                          height={35}
                          width={35}
                          loading="lazy"
                        />
                        <span className="mt-1">
                          {infoData?.assignee_full_name}
                        </span>
                      </div>
                    ) : (
                      <div className="task-custom-table-header-div-crm">
                        <FaUserAltSlash size={18} className="me-1" />
                        <span className="mt-1">
                          {Translate("Not assigned")}
                        </span>
                      </div>
                    )}
                    <span className="ms-1 mb-1">
                      {permission?.updateTaskAssignee && (
                        <UpdateAssigneeListDropDown
                          url={"crm/task-assignees/update"}
                          active={{
                            label: infoData?.assignee_full_name,
                            value: infoData?.assigned_to,
                            image: infoData?.assignee_profile,
                          }}
                          itemId={infoData?.id}
                          method={"post"}
                          clearable={true}
                          iconColor="white"
                          afterUpdate={afterAssigneeUpdate}
                        />
                      )}
                    </span>
                  </Col>
                  <Col
                    md={3}
                    sm={3}
                    xs={3}
                    className="d-flex align-items-center justify-content-center border-end"
                  >
                    <div className="task-custom-table-header-div-crm mt-1">
                      <span className="me-1">
                        {getPriorityWithProps(
                          infoData.priority,
                          "icon",
                          infoData.priority === "High" ? 20 : 25
                        )}
                      </span>
                      <span className="text-capitalize">
                        {infoData?.priority}
                      </span>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    sm={3}
                    xs={3}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div className="task-custom-table-header-div-crm mt-1">
                      <div className="d-flex align-items-center">
                        <RxLapTimer
                          className="me-1"
                          size={22}
                          color="#8bc34a"
                        />
                        <span className="text-mute mt-1">
                          {infoData.total_time_tracked}
                        </span>
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                <TabLoading />
              )}
            </Row>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {dataProcessing ? (
              <div className="h-100 d-flex align-items-center justify-content-center">
                <ListLoading />
              </div>
            ) : Object.keys(infoData).length > 0 ? (
              <>
                {Object.keys(infoData.labels).length > 0 && (
                  <Row className="mb-2 gy-2">
                    <Col>
                      <span className="d-flex gap-1 flex-wrap justify-content-start align-items-center h-100">
                        {infoData.labels.map((labelItem, key) => (
                          <span
                            key={key}
                            className="badge p-2 rounded-0"
                            style={{
                              backgroundColor: labelItem.colour,
                              fontSize: "11px",
                              cursor: "pointer",
                            }}
                            title={labelItem.description}
                          >
                            {labelItem.label}
                          </span>
                        ))}
                      </span>
                    </Col>
                  </Row>
                )}
                <Card body>
                  <div className="d-grid gap-2">
                    <div className="">
                      <span className="fw-bold d-flex flex-row gap-2">
                        <div>
                          <FaBell
                            size={22}
                            color={getTaskStatusWithProps(infoData.status)}
                          />
                        </div>
                        <div>{infoData.title}</div>
                      </span>
                    </div>
                    <Row
                      className="mb-md-1 mt-md-2 mt-1 mb-2 gy-2 shadow-sm rounded-0"
                      style={{ backgroundColor: "#f5f5f5" }}
                    >
                      <Col md={6}>
                        <div className="d-flex flex-column">
                          <div className="custom-text-muted-crm">
                            <AiOutlineCodeSandbox
                              size={15}
                              className="me-1"
                              color="#E63757"
                            />
                            {Translate("PROJECT")}:
                            <Link
                              className="text-dark ms-1"
                              to={`/project/info/${infoData.project_id}?tab=overview`}
                            >
                              {stringLimiter(infoData.project_title, 42)}
                            </Link>
                          </div>
                          {infoData?.sprint_id && (
                            <div className="custom-text-muted-crm">
                              <BsCalendar2Range
                                className="me-1"
                                color="#29BBE7"
                                size={11}
                              />
                              {Translate("SPRINT")}:
                              <Link
                                to={`/sprints/info/${
                                  infoData?.sprint_id
                                }?tab=tasklist&${createSearchParams({
                                  status_filter:
                                    defaultTaskStatusFilter.join("/"),
                                })}`}
                                className="custom-link-style-crm ms-1"
                              >
                                <span className="custom-text-muted-crm">
                                  <span className="text-capitalize">
                                    {stringLimiter(infoData?.sprint_name, 36)}
                                  </span>
                                </span>
                              </Link>
                            </div>
                          )}
                          {infoData?.product_name && (
                            <div className="custom-text-muted-crm d-flex align-items-center">
                              <TbPackage
                                color="#29BBE7"
                                size={14}
                                className="me-1"
                              />
                              {Translate("PRODUCT")}:
                              <span className="text-dark ms-1">
                                {infoData?.product_name}
                              </span>
                            </div>
                          )}
                          {infoData?.ticket && renderFrom !== "tickets" && (
                            <div>
                              <span className="custom-text-muted-crm">
                                <BiSupport
                                  color="#f5764d"
                                  size={14}
                                  className="me-1"
                                />
                                {Translate("TICKET")}:{" "}
                                <Badge
                                  bg="danger"
                                  className="fw-bold cursor-pointer"
                                  style={{ fontSize: "11px" }}
                                  onClick={() => {
                                    handleTicketInfo(infoData?.ticket);
                                  }}
                                >
                                  {infoData?.ticket?.reference}
                                </Badge>
                              </span>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="d-flex justify-content-start justify-content-md-end align-items-center mt-md-0 mt-2">
                          {infoData?.assigned_to &&
                            hasPermissionToNotify(
                              user?.id,
                              infoData?.created_by,
                              infoData?.assigned_to,
                              infoData?.id,
                              infoData?.status
                            ) && (
                              <NotifyButton
                                className="p-1 me-2 shadow"
                                variant="light"
                                onClick={() => handleNotify(infoData)}
                                iconSize={20}
                                title="Notify Assignee"
                              />
                            )}
                          {hasMatch && (
                            <div className="me-2">
                              <DigitalTimer
                                startTime={
                                  infoData?.current_time_tracker[0]?.start_time
                                }
                                handleButtonVisibility={() =>
                                  handleTimerSection(infoData)
                                }
                                loadingTimerButton={loadingTimer}
                                timerRunning={
                                  Object.keys(infoData?.current_time_tracker)
                                    .length > 0
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          )}
                          <CommonStatusUpdateDropDown
                            afterStatusChange={afterStatusChange}
                            activeStatus={infoData.status}
                            postParams={{ task_id: infoData.id }}
                            fetchUrl="crm/task-status"
                            method="post"
                            postUrl="crm/task-status/update"
                            setResponseData={setInfoData}
                            disableAfterEffects
                            isDisabled={infoData?.running_status}
                            oldStatus={infoData.status}
                            statusChange={statusChange}
                          />
                        </div>
                      </Col>
                      <Col md={12} className="d-flex justify-content-between">
                        <div className="custom-text-muted-crm d-flex align-items-center">
                          <MdOutlineDateRange
                            size={14}
                            color="#4caf50"
                            className="me-1"
                          />
                          {Translate("START DATE")}:
                          <span className="text-dark ms-1">
                            {infoData.start_date_formatted
                              ? infoData.start_date_formatted
                              : "N/A"}
                          </span>
                        </div>
                        <div className="custom-text-muted-crm d-flex align-items-center">
                          <MdOutlineDateRange
                            size={14}
                            color="#fb6262"
                            className="me-1"
                          />
                          {Translate("END DATE")}:
                          <span className="text-dark ms-1">
                            {infoData.end_date_formatted
                              ? infoData.end_date_formatted
                              : "N/A"}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {infoData?.description && (
                    <div className="pt-2 d-flex flex-column">
                      <span className="d-flex align-items-center">
                        <MdOutlineNoteAlt
                          size={17}
                          color="#f5764d"
                          className="me-1"
                        />
                        <u>{Translate("Description")}</u>
                      </span>
                      <span className="fs--2 text-wrap preview-image-wrapper">
                        {showPreview && previewImage && (
                          <ImagePreview
                            previewImage={previewImage}
                            handleClosePreview={handleClosePreview}
                          />
                        )}
                        <ShowHtmlContent
                          description={infoData?.description}
                          handleImageClick={handleImageClick}
                        />
                      </span>
                    </div>
                  )}
                  <div className={`d-flex justify-content-between mt-1`}>
                    <span className="custom-text-muted-crm d-flex align-items-center">
                      <MdDateRange size={14} color="#6b87c5" className="me-1" />
                      {Translate("Created At")}: {infoData.created_at ?? "N/A"}
                    </span>
                    <span className="custom-text-muted-crm">
                      <FaRegUserCircle
                        size={14}
                        color="#6b87c5"
                        className="me-1"
                      />
                      {Translate("Created By")}:{" "}
                      {infoData.created_user_full_name ?? "N/A"}
                    </span>
                  </div>
                </Card>
                <Tabs
                  defaultActiveKey={activeTab ? activeTab : "comments"}
                  className="mb-3 mt-3 task-info-offcanvas-header-tabs"
                  variant="tabs"
                  justify
                  onSelect={(tabname) => {
                    navigate(
                      `?${createSearchParams({
                        ...allQueryParams,
                        info_tab: tabname,
                      })}`
                    );
                  }}
                >
                  <Tab
                    className="pb-3"
                    eventKey="comments"
                    title={
                      <div className="d-flex align-items-center">
                        <BiComment size={14} color="#d68c45" />
                        <span className="ms-1">{Translate("Comments")}</span>
                      </div>
                    }
                  >
                    {showTaskInfo && activeTab === "comments" && (
                      <Comments comment_type="task" id={itemId || taskInfoId} />
                    )}
                  </Tab>
                  <Tab
                    className="pb-3"
                    eventKey="collaborators"
                    title={
                      <div className="d-flex align-items-center">
                        <AiOutlineNodeCollapse size={14} color="#E4003A" />
                        <span className="ms-1">
                          {Translate("Collaborators")}
                        </span>
                      </div>
                    }
                  >
                    {activeTab === "collaborators" && (
                      <Collaborator
                        taskId={infoData.id}
                        scroll={false}
                        assignee_id={infoData.assigned_to}
                        setCollabList={setCollabList} //for collab list for return
                      />
                    )}
                  </Tab>
                  {infoData.time_trackers.length > 0 && (
                    <Tab
                      className="pb-3"
                      eventKey="time_sheet"
                      title={
                        <div className="d-flex align-items-center">
                          <GrDocumentTime size={14} color="#52b788" />
                          <span className="ms-1">{Translate("Timesheet")}</span>
                        </div>
                      }
                    >
                      {activeTab === "time_sheet" && (
                        <TimeSheetLog infoData={infoData} />
                      )}
                    </Tab>
                  )}
                  {location?.pathname !== "/tickets/list" && (
                    <Tab
                      eventKey="remainder"
                      title={
                        <div className="d-flex align-items-center">
                          <TbClockBolt size={14} color="#FFA38F" />
                          <span className="ms-1">{Translate("Reminder")}</span>
                        </div>
                      }
                    >
                      {activeTab === "remainder" && (
                        <Reminders itemId={infoData.id} type="task" />
                      )}
                    </Tab>
                  )}
                  <Tab
                    className="pb-3 task-overview-content-tab"
                    eventKey="attachment"
                    title={
                      <div className="d-flex align-items-center">
                        <MdAttachFile size={14} color="#3FA2F6" />{" "}
                        <span className="ms-1">{Translate("Attachment")}</span>
                      </div>
                    }
                  >
                    {activeTab === "attachment" && (
                      <CommonAttachments
                        itemId={itemId || taskInfoId}
                        type={"TASK"}
                      />
                    )}
                  </Tab>
                  <Tab
                    className="pb-3"
                    eventKey="activity"
                    title={
                      <div className="d-flex align-items-center">
                        <RxActivityLog size={14} color="#B692C2" />
                        <span className="ms-1">{Translate("Activity")}</span>
                      </div>
                    }
                  >
                    {activeTab === "activity" && (
                      <ActivityTimeLine itemId={infoData?.id} />
                    )}
                  </Tab>
                  <Tab
                    className="pb-3 task-overview-content-tab"
                    eventKey="gitLog"
                    title={
                      <div className="d-flex align-items-center">
                        <FaGitAlt size={15} color="#E94D32" />
                        <span className="ms-1" style={{ width: "max-content" }}>
                          {Translate("Git Log")}
                        </span>
                      </div>
                    }
                  >
                    {activeTab === "gitLog" && <GitLog itemId={infoData?.id} />}
                  </Tab>
                </Tabs>
              </>
            ) : (
              <Alert variant="danger" className="mb-0 rounded-0 text-center">
                <h5 className="alert-heading">
                  {Translate("Nothing to show!")}
                </h5>
              </Alert>
            )}

            <TimerConfirm
              show={show}
              handleClose={handleClose}
              infoData={infoData}
              handleTimer={handleTimer}
              timerRunningTask={infoData.id}
              loading={loadingTimer}
            />
            <DeleteWarning
              show={deleteModal}
              id={infoData.id}
              onHide={(status) => handleDeleteClose(status)}
              fetchData={getData}
              setKey={(e) => console.log(e)}
              heading={"Delete Task"}
              message={"Are you sure? You want to delete this task!"}
              type={"TASK"}
              url="crm/task"
              onDelete={handleAfterDelete}
            />
          </Offcanvas.Body>
        </>
        {notifyLoading && <LoadingOverlay />}
      </Offcanvas>
      <TicketInfo
        show={ticketInfo?.show}
        id={ticketInfo?.id}
        onHide={() => {
          setTicketInfo({
            show: false,
            id: "",
          });
        }}
      />
    </>
  );
};

export default TaskInfo;
