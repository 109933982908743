import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import ActivityLoadMore from "module/common/ViewPages/Common/Activity/Timeline/ActivityLoadMore";
import React, { useEffect, useState, useCallback } from "react";
import { Accordion, Badge, Col, Row } from "react-bootstrap";
import "./GitLog.scss";
import { stringLimiter } from "helpers/utils";
import GitHubInfoLoader from "./GitLogLoader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxisproTranslate from "hooks/useAxisproTranslate";

function GitLog({ itemId, height = "30rem" }) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [nextLink, setNextLink] = useState(null);
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`crm/scm-activity-logs`, {
          params: { task_id: itemId },
        });
        setData(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [itemId]);

  const loadNextPage = useCallback(async () => {
    if (nextLink) {
      try {
        const res = await axios.get(nextLink);
        setData((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            data: [...prevData.data.data, ...res.data.data.data],
          },
        }));
        setNextLink(res.data.data.next_page_url || null);
      } catch (error) {
        showToast("Error loading more activities", "error");
      }
    }
  }, [nextLink]);

  const activities = data?.data?.data || [];

  const renderActivityDetails = (item) => (
    <>
      {item?.reference_id && (
        <div>
          <strong>{Translate("Commit")}:</strong> {item.reference_id}
        </div>
      )}
      {item?.author_name && (
        <div>
          <strong>{Translate("Author Name")}:</strong> {item.author_name}
        </div>
      )}
      {item?.branch_from && (
        <div>
          <strong>{Translate("Branch From")}:</strong> {item.branch_from}
        </div>
      )}
      {item?.branch_to && (
        <div>
          <strong>{Translate("Branch To")}:</strong> {item.branch_to}
        </div>
      )}
      {item?.status && (
        <div>
          <strong>{Translate("Status")}:</strong>{" "}
          <Badge
            className="text-capitalize fs--2 rounded-pill"
            bg={
              item.status === "closed"
                ? "danger"
                : item.status === "merged"
                ? "success"
                : "info"
            }
          >
            {item.status}
          </Badge>
        </div>
      )}
      {item?.committed_at && (
        <div>
          <strong>{Translate("Committed At")}:</strong>{" "}
          {moment(item.committed_at).format("D MMM YYYY h:mm A")}
        </div>
      )}
      {item?.merged_by && (
        <div>
          <strong>{Translate("Merged By")}:</strong> {item.merged_by}
        </div>
      )}
      {item?.merged_by && item?.merged_at && (
        <div>
          <strong>{Translate("Merged At")}:</strong> {item.merged_at_formatted}
        </div>
      )}
      {item?.pr_title && <div className="mt-3">{item.pr_title}</div>}
      {item?.description && <div>{item.description}</div>}
      {item?.url && (
        <div className="mt-2">
          <a
            href={item?.url}
            target="_blank"
            rel="noopener noreferrer"
            title="View in Github"
          >
            {stringLimiter(item?.pr_title, 40, "-")}
            <FontAwesomeIcon
              icon="external-link-alt"
              className="ms-1 mb-1 text-primary"
              size="xs"
              cursor={"pointer"}
            />
          </a>
        </div>
      )}
    </>
  );

  return (
    <Row>
      <Col md={12}>
        <div
          className="widget-wrap"
          style={{ maxHeight: height, overflowY: "auto" }}
        >
          {loading ? (
            <GitHubInfoLoader height={height} items={10} />
          ) : activities.length > 0 ? (
            <Accordion activeKey={expanded}>
              {activities.map((item, index) => (
                <div
                  key={index}
                  className={`git-graph-container-div ${
                    expanded === index ? "expanded" : ""
                  }`}
                >
                  <div
                    className="git-graph-container-wrapper cursor-pointer"
                    onClick={() =>
                      setExpanded(expanded === index ? null : index)
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <Badge className="text-capitalize fs--2">
                          {item?.activity_type.replace(/_/g, " ")}
                        </Badge>
                        <span className="ps-2">
                          {stringLimiter(item?.pr_title, 35, "-")}
                        </span>
                      </div>
                      <div>
                        <span className="ps-2">
                          {moment(item?.activity_timestamp).format(
                            "D MMM YYYY h:mm A"
                          )}
                        </span>
                        <span className="ps-2">
                          {stringLimiter(item?.author_name, 15, "-")}
                        </span>
                      </div>
                    </div>
                    <Accordion.Collapse eventKey={index}>
                      <div className="p-2 mt-2 git-graph-accordion-content">
                        {renderActivityDetails(item)}
                      </div>
                    </Accordion.Collapse>
                  </div>
                </div>
              ))}
            </Accordion>
          ) : (
            <div className="text-center w-100 mt-5">
              {Translate("No Data")}
              <span className="fs-1">⚠️</span>
            </div>
          )}
          {nextLink && (
            <ActivityLoadMore nextLink={nextLink} loadNextPage={loadNextPage}>
              <GitHubInfoLoader height="24rem" items="10" />
            </ActivityLoadMore>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default GitLog;
