import { React, useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconCardHeader from "components/common/FalconCardHeader";
import TodoListItem from "./TodoListItem";
import axios from "axios";
import ListLoading from "module/common/ListLoading";
import SimpleBar from "simplebar-react";
import { crmDashboardColors } from "../CrmStats/CrmStatsData";
import TodoForm from "module/Todo/TodoForm";
import "./TodoListItem.scss";
import TodoInfo from "module/Todo/TodoInfo";
import { DeleteContext } from "context/Context";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import ReminderForm from "module/common/Reminder/ReminderForm";
import NothingToShow from "../NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import {
  getFormattedCurrentDateTime,
  getFormattedEndDateTime,
} from "helpers/utils";
const TodoListTile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const [data, setData] = useState([]);
  const Translate = useAxisproTranslate();
  const [todoInfo, setTodoInfo] = useState({
    showForm: false,
    showInfo: false,
    id: "",
  });
  const [reminderDetails, setReminderDetails] = useState({
    show: false,
    details: {
      source: "",
      source_id: "",
      reminder_id: "",
    },
  });
  const permission = {
    store: GetAcodaxPermission("TODO", "store"),
  };
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get("crm/crm-todo", {
        params: {
          from_date: getFormattedCurrentDateTime("YYYY-MM-DD"),
          to_date: getFormattedEndDateTime(null, "YYYY-MM-DD", "days", 6),
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.data.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleChange = (id, completed) => {
    const updatedTodos = data.map((item) =>
      item.id === id ? { ...item, completed } : item
    );
    setData(updatedTodos);
  };

  const onButtonClick = (action, id) => {
    switch (action) {
      case "remove":
        dispatch({
          type: "CONFIG",
          payload: {
            target: data,
            url: "/crm/crm-todo/" + id,
            title: "Delete Todo item",
            message: "Are you sure you want to delete this todo?",
            onSuccess: () => {
              const dataWithoutDeletedItem = data?.filter(
                (todo) => todo.id !== id
              );
              setData(dataWithoutDeletedItem);
              dispatch({
                type: "RESET",
              });
            },
          },
        });
        break;
      case "edit":
        setTodoInfo({
          showForm: true,
          showInfo: false,
          id: id,
        });
        break;
      case "clock":
        setReminderDetails({
          show: true,
          details: {
            source: "todo",
            source_id: id,
            reminder_id: "",
          },
        });
        break;
      case "view":
        setTodoInfo({
          showInfo: true,
          showForm: false,
          id: id,
        });
        break;
      case "form":
        setTodoInfo({
          showForm: true,
          showInfo: false,
          id: "",
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Card className="h-100 card-main">
        <FalconCardHeader
          title="To Do List"
          titleTag="h6"
          titleClass="fw-bold fs-0 mt-1"
        />
        <Card.Body className="p-0 overflow-hidden">
          {isLoading ? (
            <div className="h-100 p-3">
              <ListLoading
                style={{ maxHeight: "26.6vh", overflow: "hidden" }}
              />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <SimpleBar
                  style={{ maxHeight: "30vh" }}
                  className="todo-list-tile-simple-bar"
                >
                  {data.map((item, index) => (
                    <TodoListItem
                      key={item.id}
                      item={item}
                      color={
                        crmDashboardColors[index % crmDashboardColors.length]
                      }
                      index={index}
                      length={data.length}
                      setData={setData}
                      handleChange={handleChange}
                      handleAction={onButtonClick}
                    />
                  ))}
                </SimpleBar>
              ) : (
                <div className="mt-3 h-100">
                  <NothingToShow />
                </div>
              )}
            </>
          )}
        </Card.Body>
        <Card.Footer className="p-0 bg-body-tertiary">
          {permission?.store && (
            <Button
              variant="link"
              size="sm"
              className="w-100 py-2"
              onClick={() => {
                onButtonClick("form");
              }}
            >
              <FontAwesomeIcon icon="plus" className="me-1 fs-11" />
              {Translate("Add New Todo")}
            </Button>
          )}
        </Card.Footer>
        <TodoForm
          show={todoInfo.showForm}
          id={todoInfo.id}
          fetchData={fetchData}
          onHide={() =>
            setTodoInfo({ showForm: false, showInfo: false, id: "" })
          }
        />
        <TodoInfo
          show={todoInfo?.showInfo}
          id={todoInfo?.id}
          onHide={() => {
            setTodoInfo({
              showInfo: false,
              showForm: false,
              id: "",
            });
          }}
        />
        <ReminderForm
          show={reminderDetails?.show}
          details={reminderDetails?.details}
          onHide={() => setReminderDetails({ show: false, details: {} })}
        />
      </Card>
    </>
  );
};

export default TodoListTile;
