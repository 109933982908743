import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import GaugeEChart from "module/common/Charts/GaugeEChart";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListLoading from "module/common/ListLoading";
import NothingToShow from "../NothingToShow";

function TaskCompletionRate({ dashboardData, loading }) {
  const Translate = useAxisproTranslate();
  const deployedTasks = dashboardData?.status_info?.find(
    (item) => item.status === "deployed"
  );

  const total_no_of_task_completed = Math.round(
    dashboardData?.total_no_of_task *
      (dashboardData?.tasks_complete_percentage / 100)
  );

  return (
    <Card className="h-100 card-main">
      {loading ? (
        <div className="p-3">
          <ListLoading style={{ maxHeight: "17.3vh", overflow: "hidden" }} />
        </div>
      ) : (
        <>
          <Card.Header className="pb-0 ps-2">
            <h6 className="fw-bold fs-0 p-0 ms-2">
              {Translate("Tasks Completion Rate")}
            </h6>
          </Card.Header>
          {Object.keys(dashboardData).length > 0 ? (
            <Card.Body className="p-3 pt-0">
              <div className="d-flex justify-content-around align-items-center mt-3">
                <div>
                  <div className="mb-3">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="clear-filter-toolptip" className="small">
                          {dashboardData?.total_tasks_completed ??
                            total_no_of_task_completed}{" "}
                          {Translate("Tasks of")}{" "}
                          {dashboardData?.total_no_of_task}{" "}
                          {Translate("Completed")}
                        </Tooltip>
                      }
                    >
                      <div
                        className="text-500 fs-2"
                        style={{
                          fontWeight: 500,
                          fontFamily: "poppins",
                        }}
                      >
                        {dashboardData?.total_tasks_completed ??
                          total_no_of_task_completed}
                        /{dashboardData?.total_no_of_task}
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-1 justify-content-between gap-3">
                    <p className="m-0 fs--2">
                      <FontAwesomeIcon
                        icon="circle"
                        className="text-success fs--2 me-2"
                      />
                      {Translate("Deployed Tasks")}
                    </p>
                    <h5 className="m-0 fs--2 text-success">
                      {deployedTasks?.count}
                    </h5>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-1 justify-content-between gap-3">
                    <p className="m-0 fs--2">
                      <FontAwesomeIcon
                        icon="circle"
                        className="text-warning fs--2 me-2"
                      />
                      {Translate("Pending Tasks")}
                    </p>
                    <h5 className="m-0 fs--2 text-warning">
                      {dashboardData?.total_tasks_pending ?? "0"}
                    </h5>
                  </div>
                </div>
                <GaugeEChart
                  height="130px"
                  width="130px"
                  value={dashboardData?.tasks_complete_percentage}
                  borderRadius="50%"
                />
              </div>
            </Card.Body>
          ) : (
            <div className="h-100">
              <NothingToShow />
            </div>
          )}
        </>
      )}
    </Card>
  );
}

TaskCompletionRate.propTypes = {
  dashboardData: PropTypes.any,
  loading: PropTypes.bool,
};

export default TaskCompletionRate;
