import React, { useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";
import { Button } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const SelectAccountName = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  onFocus,
  existingValue,
  setIsLoading,
}) => {
  const Translate = useAxisproTranslate();
  const [searchText, setSearchText] = useState(existingValue || "");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef(null);
  const hasFetched = useRef(false);

  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: "sales/get-matching-accounts",
      setParams: (searchKey) => ({ account_name: searchKey || searchText }),
      setOptions: (option) => setOptionData(option),
      onFocus: onFocus,
    });

  const setOptionData = (option) => ({
    label: option?.name,
    value: option?.id,
  });

  const handleAddNew = () => {
    if (searchText.trim()) {
      const newOption = { label: searchText, value: searchText, isNew: true };

      handleFieldChange(newOption, {
        action: "select-option",
        option: undefined,
        name: "account_name",
      });

      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.blur();
          setMenuIsOpen(false);
          setSearchText("");
        }
      }, 100);
    }
  };

  const NoOptionsMessage = () => {
    return searchText ? (
      <div className="d-flex justify-content-between align-items-center px-2">
        <span className="text-dark">
          {Translate("No options found, do you want to add")} "
          <strong>{searchText}</strong>"?
        </span>
        <Button
          variant="transparent"
          className="p-0 custom-transperent-common-button"
          onClick={handleAddNew}
          size="sm"
        >
          <span className="text-primary">
            {Translate("Add")} <FaPlusCircle size={14} className="mb-1" />
          </span>
        </Button>
      </div>
    ) : (
      <span className="px-2">{Translate("No options")}</span>
    );
  };

  useEffect(() => {
    if (existingValue && !hasFetched.current) {
      setIsLoading && typeof setIsLoading === "function" && setIsLoading(true);
      hasFetched.current = true;
      fetchData(existingValue).then(() => {
        setDefaultResult();
        setMenuIsOpen(true);
        if (selectRef.current) {
          selectRef.current.focus();
        }
        setIsLoading &&
          typeof setIsLoading === "function" &&
          setIsLoading(false);
      });
    }
  }, [existingValue, fetchData, setDefaultResult, setIsLoading]);

  return (
    <AsyncSelect
      ref={selectRef}
      cacheOptions
      loadOptions={(inputValue, callback) => {
        setSearchText(inputValue);
        fetchData(inputValue).then(callback);
      }}
      isSearchable={true}
      defaultOptions={defaultOptions}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      onFocus={setDefaultResult}
      isLoading={loading}
      isClearable={true}
      name={name ?? "account_name"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={(e, action) => {
        handleFieldChange(e, action);
        setMenuIsOpen &&
          typeof setMenuIsOpen === "function" &&
          setMenuIsOpen(false);
      }}
      className={`custom-select ${
        error ? "form-control ps-0 py-0 is-invalid " : ""
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused
            ? state.isSelected
              ? "#192E49"
              : "#e1e2ea"
            : state.isSelected
            ? "#696c96"
            : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
      noOptionsMessage={NoOptionsMessage}
    />
  );
};

SelectAccountName.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  existingValue: PropTypes.string,
  setIsLoading: PropTypes.func,
};

export default SelectAccountName;
