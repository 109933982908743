import React, { useEffect, useState, useMemo } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
// import bg2 from "assets/img/icons/spot-illustrations/corner-2.png";
// import Background from "components/common/Background";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import Carousel from "react-bootstrap/Carousel";
import { FaListUl, FaPlusCircle } from "react-icons/fa";
import NothingToShow from "module/Dashboard/NothingToShow";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

function StatusCarousel({
  statusArray,
  totalCount,
  loading,
  loadingBox = 6,
  permission,
  countLabel,
  handleAction,
  store,
  list,
}) {
  const Translate = useAxisproTranslate();
  const [carouselItems, setCarouselItems] = useState([]);
  const data = Array.from({ length: loadingBox }, (_, index) => index + 1);
  const allStatusInfo = useMemo(() => {
    const statusInfo = statusArray || [];
    return [totalCount, ...statusInfo];
  }, [statusArray, totalCount]);
  const chunkSize = 6;

  const repeatStatusInfoArray = (chunk) => {
    if (chunk.length >= chunkSize) {
      return chunk;
    }
    const remainingChunks = chunkSize - chunk.length;
    return [...chunk, ...allStatusInfo?.slice(0, remainingChunks)];
  };

  useEffect(() => {
    if (!loading && allStatusInfo.length > 1) {
      const chunkedData = chunkArray(allStatusInfo, chunkSize);
      setCarouselItems(chunkedData);
    }
  }, [loading, allStatusInfo]);

  return (
    <>
      {loading ? (
        <Carousel className="task-carousal">
          {data.map((key) => (
            <Carousel.Item key={key} className="px-2">
              <Row className="d-flex justify-content-center">
                {Array.from({ length: 6 }).map((_, index) => (
                  <Col key={index} xs={12} md={6} lg={4} xl={2} className="p-2">
                    <Card className="mt-2 card-main">
                      {/* <Background image={bg2} className="bg-card" /> */}
                      <Card.Body>
                        <DashboardTileLoader column={1} />
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : carouselItems?.length > 0 ? (
        <Carousel className="task-carousal px-2">
          {carouselItems.map((chunk, chunkIndex) => (
            <Carousel.Item key={chunkIndex} className="px-2">
              <Row className="d-flex justify-content-start px-2">
                {repeatStatusInfoArray(chunk).map((info, key) => (
                  <Col
                    key={key}
                    xs={12}
                    md={6}
                    lg={4}
                    xl={2}
                    className="pb-2 d-flex px-2"
                  >
                    <Card
                      key={key}
                      className="card-main mt-2 flex-fill cursor-pointer"
                      onClick={(e) => {
                        handleAction(e, "navigation", info);
                      }}
                    >
                      {/* <Background image={bg2} className="bg-card card-main" /> */}
                      <Card.Body>
                        <div className="d-grid justify-content-center align-items-center">
                          <span className="d-flex justify-content-center">
                            {info.icon}
                          </span>
                          <span
                            className="fs-1 mt-2 text-capitalize text-center"
                            style={{ color: "rgb(1,0,1)" }}
                          >
                            {Translate(info.label)}
                          </span>
                          <span className="mt-2 d-flex justify-content-center">
                            {info.value} {Translate(countLabel)}
                          </span>
                        </div>

                        {info.key === "total_count" && (
                          <div
                            className="d-flex justify-content-between"
                            style={{
                              position: "absolute",
                              zIndex: "2",
                              bottom: "10px",
                              right: "10px",
                              gap: "5px",
                            }}
                          >
                            {store && (
                              <Button
                                variant="transparent"
                                className={`p-0 custom-transperent-common-button ${
                                  permission?.store ? "" : "invisible"
                                }`}
                                size="sm"
                                onClick={(e) => handleAction(e, "store")}
                                title={Translate(`Create New ${countLabel}`)}
                              >
                                <div className="d-flex align-items-center gap-1">
                                  <FaPlusCircle size={15} />
                                </div>
                              </Button>
                            )}
                            {list && (
                              <Button
                                variant="transparent"
                                className={`p-0 custom-transperent-common-button ${
                                  permission?.show ? "" : "invisible"
                                }`}
                                size="sm"
                                onClick={(e) => handleAction(e, "list")}
                                title={Translate(`Show All ${countLabel}`)}
                              >
                                <div className="d-flex align-items-center gap-1">
                                  <FaListUl size={15} />
                                </div>
                              </Button>
                            )}
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div className="h-100 px-3">
          <Card className="h-100 card-main p-3">
            <h6 className="fw-bold fs-0 p-0 mb-0">
              {Translate(`${countLabel} Summary`)}
            </h6>
            <div className="h-100 pt-3">
              <NothingToShow />
            </div>
          </Card>
        </div>
      )}
    </>
  );
}

StatusCarousel.propTypes = {
  statusArray: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    })
  ).isRequired,
  totalCount: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  loadingBox: PropTypes.number,
  permission: PropTypes.object,
  countLabel: PropTypes.string,
  handleAction: PropTypes.func,
  store: PropTypes.bool,
  list: PropTypes.bool,
};

export default StatusCarousel;
