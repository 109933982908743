import AppDatePicker from "components/app-date-picker/AppDatePicker";
import BackButton from "components/common/BackButton";
import SelectDocumentType from "components/form/SelectDocumentType";
import { clientKYCFormKeys } from "helpers/formKeys";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import React, { useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdFilePresent } from "react-icons/md";

function KycModal({ show, handleClose, handleFiles }) {
  const Translate = useAxisproTranslate();
  const [formData, setFormData] = useState(clientKYCFormKeys);
  const [formError, setFormError] = useState({});
  const fileRef = useRef();
  const handleFieldChange = (e, action) => {
    setFormData((prevFormData) => {
      let newFormData;
      let value;
      let fieldName;
      let fieldValue;

      if (!action) {
        if (e.target.name === "files") {
          value = e.target.files[0];
        } else {
          value = e.target.value;
        }
        newFormData = {
          ...prevFormData,
          [e.target.name]: value,
        };
      } else {
        fieldName = action.name.replace("_id", "_name");
        if (action.action === "select-option") {
          value = e.code ? e.code : e.value;
          fieldValue = e.label ? e.label : "";
        }
        if (action.action === "clear") {
          fieldValue = null;
        }
        newFormData = {
          ...prevFormData,
          [fieldName]: fieldValue,
          [action.name]: value,
          [action.name + "_ref"]: e,
        };
      }

      return newFormData;
    });
  };

  const handleModalClose = () => {
    setFormData(clientKYCFormKeys);
    fileRef.current.value = "";
    setFormError({});
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.document_type) {
      errors.document_type = "Document type is required.";
    }
    if (!formData.expiry_date) {
      errors.expiry_date = "Expiry date is required.";
    }
    if (formData.files.length === 0) {
      errors.files = "Please attach a document.";
    }
    if (Object.keys(errors).length > 0) {
      setFormError(errors);
      return;
    }
    handleFiles(formData);
    handleModalClose();
  };

  return (
    <Modal
      size="md"
      show={show}
      className="modal-with-overlay"
      animation
      centered
      onHide={handleModalClose}
    >
      <Modal.Header className="fs-1 text-dark d-flex justify-content-between">
        <span>
          <MdFilePresent size={26} color="#2e2e2e" className="me-1" />
          {Translate("Add Document")}
        </span>
        <CustomBackButton
          variant="falcon-default"
          title="Close"
          onClick={() => handleModalClose()}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">
              {Translate("Document Type")}
            </Form.Label>
            <SelectDocumentType
              value={formData.document_type_ref}
              name="document_type"
              handleFieldChange={handleFieldChange}
            />
            {formError.document_type && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {formError.document_type}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">
              {Translate("Expiry Date")}
            </Form.Label>
            <AppDatePicker
              name="expiry_date"
              yearPlaceholder="yyyy"
              monthPlaceholder="mm"
              dayPlaceholder="dd"
              onChange={handleFieldChange}
              value={formData.expiry_date}
            />
            {formError.expiry_date && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {formError.expiry_date}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label className="require-data">
              {Translate("Attachment")}
            </Form.Label>
            <Form.Control
              type="file"
              name="files"
              onChange={handleFieldChange}
              ref={fileRef}
            />
            {formError.files && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {formError.files}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="mt-3">
            <div className="d-flex justify-content-end align-items-center">
              <Button size="sm" type="submit" variant={"info"} className="me-1">
                {Translate("Add")}
              </Button>
              <BackButton
                variant={"danger"}
                size={"sm"}
                style={{ height: "fit-content" }}
                onClick={handleModalClose}
              >
                {Translate("Cancel")}
              </BackButton>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default KycModal;
