import React, { useEffect, useState, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import BackButton from "components/common/BackButton";
import SelectAccountName from "components/form/SelectAccountName";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { CustomBackButton } from "module/common/Buttons/AppButtons";

function SearchMatchingAccounts({
  show,
  handleClose,
  accountName,
  handleFieldChange,
}) {
  const Translate = useAxisproTranslate();
  const [formData, setFormData] = useState({
    account_name: "",
    account_name_ref: { label: "", value: "" },
  });
  const [formDataReference, setFormDataReference] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (accountName) {
      setFormData({
        account_name: accountName,
        account_name_ref: { label: accountName, value: accountName },
      });
    }
  }, [accountName]);

  const handleChange = useCallback((e, action) => {
    setFormData((prev) => generateFormDataHelper(e, action, prev));
    setFormDataReference((prev) => generateFormDataHelper(e, action, prev));
  }, []);

  const handleAdd = () => {
    if (!formData.account_name) return;
    if (formData.account_name_ref?.isNew) {
      handleFieldChange({
        target: { name: "account_name", value: formData.account_name },
      });
      handleFieldChange({
        target: {
          name: "account_name_reference",
          value: formData.account_name,
        },
      });
    } else {
      handleFieldChange(formData.account_name_ref, {
        action: "select-option",
        name: "customer_id",
      });
      handleFieldChange({ target: { name: "is_existing_account", value: 1 } });
      handleFieldChange({ target: { name: "account_name", value: "" } });
    }
    handleClose();
  };

  return (
    <Modal
      size="md"
      show={show}
      className="modal-with-overlay"
      animation
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <Modal.Header className="fs-1 text-dark d-flex justify-content-between">
        <span>
          <FaSearch size={20} color="#2e2e2e" className="me-1" />
          {Translate("Search Matching Accounts")}
        </span>
        <CustomBackButton
          variant="falcon-default"
          title="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <SelectAccountName
          name="account_name"
          value={formData.account_name_ref}
          handleFieldChange={handleChange}
          existingValue={formData.account_name}
          setIsLoading={setIsLoading}
        />
        <div className="text-dark mt-3">
          {isLoading ? (
            <div>{Translate("Loading")}...</div>
          ) : formDataReference.account_name ? (
            <div>
              {Translate(
                formDataReference.account_name_ref?.isNew
                  ? "This is a new Account name. By adding, it will go to the New Account"
                  : "This is an existing Account. By adding, it will go to the Existing Account"
              )}
            </div>
          ) : (
            <div>{Translate("Please select an Account before adding")}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            size="sm"
            variant="info"
            className="me-1"
            onClick={handleAdd}
            disabled={!formDataReference.account_name || isLoading}
          >
            {Translate("Add")}
          </Button>
          <BackButton
            variant="danger"
            size="sm"
            style={{ height: "fit-content" }}
            onClick={handleClose}
          >
            {Translate("Cancel")}
          </BackButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default SearchMatchingAccounts;
