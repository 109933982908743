import axios from "axios";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import BackButton from "components/common/BackButton";
import MultiSelectContacts from "components/form/MultiSelectContacts";
import SelectCallPurpose from "components/form/SelectCallPurpose";
import SelectCallResult from "components/form/SelectCallResult";
import SelectCallTo from "components/form/SelectCallTo";
import SelectCallType from "components/form/SelectCallType";
import SelectMeetingStatus from "components/form/SelectMeetingStatus";
import SelectUsers from "components/form/SelectUsers";
import { apiCall } from "helpers/apiCalls";
import { eventFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import {
  getFormattedCurrentDateTime,
  getFormattedEndDateTime,
} from "helpers/utils";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import { SaveButton } from "module/common/Buttons/CommonButton";
import ListLoading from "module/common/ListLoading";
import { showToast } from "module/common/Toast/toast";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { MdEvent } from "react-icons/md";

function EventForm({ show, onHide, data = {}, handleRefresh }) {
  const { id = "", module = "", start_time = "", end_time = "" } = data;
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    ...eventFormKeys,
    start_time: getFormattedCurrentDateTime("YYYY-MM-DD HH:mm:ss"),
    end_time: getFormattedEndDateTime(
      null,
      "YYYY-MM-DD HH:mm:ss",
      "minutes",
      30
    ),
  });
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const handleFieldChange = (e, action) => {
    setFormData((prev) => generateFormDataHelper(e, action, prev));
  };

  const fetchData = useCallback(
    async (id) => {
      setIsLoading(true);
      const url = module === "call" ? `crm/calls/${id}` : `crm/meetings/${id}`;
      const data = await apiCall({
        url: url,
      });

      const participantsArray = data?.participants?.map((item) => ({
        label: item?.full_name,
        value: item?.id,
      }));
      setFormData((prev) => ({
        ...prev,
        event: module,
        title: data?.title,
        start_time: data?.start_time,
        end_time: data?.end_time,
        host_user_id: data?.host_user_id,
        host_user_id_ref: {
          label: data?.host?.first_name + " " + data?.host?.last_name,
          value: data?.host_user_id,
        },
        participants: data?.participants?.map((items) => items.id),
        location: data?.location,
        participants_ref: participantsArray,
        description: data?.description,
        status: data?.status?.toUpperCase(),
        status_ref: {
          label: data?.status?.toUpperCase(),
          value: data?.status?.toUpperCase(),
        },
        call_to: data?.call_to,
        call_to_ref: {
          label:
            data?.call_to_contact?.phone_no +
            " - " +
            data?.call_to_contact?.first_name,
          value: data?.call_to,
        },
        out_call_result: data?.out_call_result,
        out_call_result_ref: {
          label: data?.out_call_result,
          value: data?.out_call_result,
        },
        purpose: data?.purpose,
        purpose_ref: { label: data?.purpose, value: data?.purpose },
        type: data?.type,
        type_ref: { label: data?.type, value: data?.type },
      }));
      setIsLoading(false);
    },
    [module]
  );

  useEffect(() => {
    if (id && show) {
      fetchData(id);
    }
  }, [fetchData, id, show]);

  useEffect(() => {
    if (start_time && end_time) {
      setFormData((prev) => ({
        ...prev,
        start_time: start_time,
        end_time: end_time,
      }));
    }
  }, [start_time, end_time]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);
    const removeRefDataResults = removeRefData(formData);
    const newFormData = setNewFormData(removeRefDataResults);
    const baseUrl = formData?.event === "call" ? "crm/calls" : "crm/meetings";
    axios({
      method: id ? "put" : "post",
      url: id ? `${baseUrl}/${id}` : baseUrl,
      data: newFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        const responseData = response?.data;
        if (responseData?.success === true) {
          showToast(responseData?.message, "success");
          handleRefresh();
          handleClose();
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        const errorData = error?.response?.data;
        showToast(
          errorData?.message ??
            error?.message ??
            "Something went wrong, please refresh the page and try again.",
          "error"
        );
        setFormError({ ...(errorData?.data?.errors ?? null) });
      })
      .finally(() => {
        setOnSave(false);
      });
  };

  const handleClose = () => {
    setFormData({
      ...eventFormKeys,
      start_time: getFormattedCurrentDateTime("YYYY-MM-DD HH:mm:ss"),
      end_time: getFormattedEndDateTime(
        null,
        "YYYY-MM-DD HH:mm:ss",
        "minutes",
        30
      ),
    });
    onHide();
  };

  return (
    <Modal
      size="lg"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      className="no-border-radius-modal"
      animation
      onHide={handleClose}
    >
      <Modal.Header className="fs-1 text-dark d-flex justify-content-between">
        <span>
          <MdEvent size={26} color="#2e2e2e" className="me-1" />
          {Translate(id ? "Update Event" : "Schedule Event")}
        </span>
        <CustomBackButton
          variant="falcon-default"
          title="Close"
          onClick={() => handleClose()}
        />
      </Modal.Header>
      {isLoading ? (
        <div className="p-2">
          <ListLoading style={{ height: "56.5vh", overflow: "hidden" }} />
        </div>
      ) : (
        <>
          <Modal.Body>
            <Row>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Label>{Translate("Event")}</Form.Label>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="event"
                        value={formData?.event}
                        onChange={handleFieldChange}
                      >
                        <option value="call">{Translate("Call")}</option>
                        <option value="meeting">{Translate("Meeting")}</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {formData?.event === "call" ? (
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data">
                          {Translate("Purpose")}
                        </Form.Label>
                        <SelectCallPurpose
                          name="purpose"
                          className="rounded-0"
                          value={formData?.purpose_ref}
                          handleFieldChange={handleFieldChange}
                          error={!!formError?.purpose}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.purpose}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : (
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data">
                          {Translate("Title")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          value={formData?.title}
                          onChange={handleFieldChange}
                          isInvalid={!!formError?.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                      <Form.Label className="require-data">
                        {Translate("Start Time")}
                      </Form.Label>
                      <AppTimePicker
                        name="start_time"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                        onChange={handleFieldChange}
                        value={formData?.start_time}
                        isInvalid={!!formError?.start_time}
                      />
                      <Form.Control.Feedback type="is-invalid">
                        {formError?.start_time}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {formData?.event === "call" ? (
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data">
                          {Translate("Call To")}
                        </Form.Label>
                        <SelectCallTo
                          name="call_to"
                          value={formData?.call_to_ref}
                          handleFieldChange={handleFieldChange}
                          error={!!formError?.call_to}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.call_to}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : (
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data">
                          {Translate("Host")}
                        </Form.Label>
                        <SelectUsers
                          name="host_user_id"
                          value={formData?.host_user_id_ref}
                          handleFieldChange={handleFieldChange}
                          error={!!formError?.host_user_id}
                          isClearable={false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.host_user_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    {formData?.event === "call" ? (
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data">
                          {Translate("Type")}
                        </Form.Label>
                        <SelectCallType
                          name="type"
                          value={formData?.type_ref}
                          handleFieldChange={handleFieldChange}
                          error={!!formError?.type}
                          isClearable={false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : (
                      <Form.Group className="mb-3">
                        <Form.Label>{Translate("Participant")}</Form.Label>
                        <MultiSelectContacts
                          name="participants"
                          value={formData?.participants_ref}
                          onChange={handleFieldChange}
                          error={!!formError?.participants}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.participants}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    <Form.Group className="mb-1">
                      <Form.Label className="require-data">
                        {Translate("Status")}
                      </Form.Label>
                      <SelectMeetingStatus
                        name="status"
                        value={formData?.status_ref}
                        handleFieldChange={handleFieldChange}
                        error={!!formError?.status}
                        isClearable={false}
                      />

                      <Form.Control.Feedback type="invalid">
                        {formError?.status}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    {formData?.event === "call" ? (
                      <Form.Group className="mb-3">
                        <Form.Label
                          className={
                            formData?.status === "SCHEDULED"
                              ? ""
                              : "require-data"
                          }
                        >
                          {Translate("Call Result")}
                        </Form.Label>
                        <SelectCallResult
                          name="out_call_result"
                          value={formData?.out_call_result_ref}
                          handleFieldChange={handleFieldChange}
                          error={!!formError?.out_call_result}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.out_call_result}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : (
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data">
                          {Translate("Location")}
                        </Form.Label>
                        <Form.Control
                          name="location"
                          value={formData?.location}
                          onChange={handleFieldChange}
                          isInvalid={!!formError?.location}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError?.location}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                      <Form.Label className="require-data">
                        {Translate("End Time")}
                      </Form.Label>
                      <AppTimePicker
                        name="end_time"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                        onChange={handleFieldChange}
                        value={formData?.end_time}
                        isInvalid={!!formError?.end_time}
                      />
                      <Form.Control.Feedback type="is-invalid">
                        {formError?.end_time}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>
                        {Translate(
                          `${
                            formData?.event === "call" ? "Call" : "Meeting"
                          } Agenda`
                        )}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={10}
                        name="description"
                        onChange={handleFieldChange}
                        value={formData?.description}
                        isInvalid={!!formError?.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Modal.Body>
          <Form.Group className="d-flex justify-content-end gap-1 border-top p-3">
            <SaveButton handleSubmit={handleSubmit} onSave={onSave} id={id} />
            {!onSave && (
              <BackButton variant={"danger"} size={"sm"} onClick={handleClose}>
                {Translate("Cancel")}
              </BackButton>
            )}
          </Form.Group>
        </>
      )}
    </Modal>
  );
}

export default EventForm;
