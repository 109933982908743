import { React } from "react";
import { Col, Row } from "react-bootstrap";
import OpenTicketsGroup from "./OpenTicketsGroup";
import PropTypes from "prop-types";

function TicketSummaryTilesGroup({ ticketSummary, ticketSummaryLoading }) {
  return (
    <Row className="g-3 h-100">
      <Col xxl={12} xl={12} lg={12} md={12} className="h-100">
        <OpenTicketsGroup
          ticketSummary={ticketSummary}
          ticketSummaryLoading={ticketSummaryLoading}
        />
      </Col>
    </Row>
  );
}

TicketSummaryTilesGroup.propTypes = {
  ticketSummary: PropTypes.any,
  ticketSummaryLoading: PropTypes.bool,
};

export default TicketSummaryTilesGroup;
