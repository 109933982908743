import { React, useEffect, useState } from "react";
import axios from "axios";
import { Badge, Col, Image, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { FaCircle } from "react-icons/fa";
import "./ActivityTimeLine.scss";
import DefaultAvathar from "assets/img/Avathar/ProfileImage.jpg";
import ActivityLogLoader from "module/common/ViewPages/Common/Activity/Timeline/ActivityLogLoader";
import { showToast } from "module/common/Toast/toast";
import ActivityLoadMore from "./ActivityLoadMore";
import Accordion from "react-bootstrap/Accordion";
import { MdExpandMore } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const ActivityTimeLine = ({
  infoData,
  triggerActivityLog,
  itemId,
  height = "30rem",
  customerId,
  userId,
}) => {
  const Translate = useAxisproTranslate();
  const [loading, setLoading] = useState(false);
  const [nextLink, setNextLink] = useState(null);
  const [data, setData] = useState(infoData || { activity_log: [] });
  const [showDetails, setShowDetails] = useState();

  const fetchData = () => {
    setLoading(true);
    axios
      .get("activity-log", {
        params: {
          subject_id: itemId,
          customer_id: customerId,
          user_id: userId,
        },
      })
      .then((res) => {
        if (res.data.success) {
          const activityLogData = {
            activity_log: res?.data?.data?.data,
          };
          setNextLink(res.data.data?.next_page_url);
          setData(activityLogData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!infoData) {
      fetchData();
    } else {
      setData(infoData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoData, itemId, triggerActivityLog, customerId, userId]);

  const loadNextPage = async () => {
    if (nextLink && nextLink !== "") {
      await axios
        .get(nextLink)
        .then((res) => {
          setData((prevData) => ({
            ...prevData,
            activity_log: [...prevData.activity_log, ...res?.data?.data?.data],
          }));
          if (res.data.data?.next_page_url !== null) {
            setNextLink(res.data.data?.next_page_url);
          } else {
            setNextLink(null);
          }
        })
        .catch((error) => {
          if (error) {
            showToast("Something went wrong please contact admin", "error");
          }
        });
    }
  };

  const handleDetails = (id) => {
    setShowDetails(showDetails === id ? null : id);
  };

  function displayActivityLog(data) {
    const excludedKeys = [
      "id",
      "updated_by",
      "created_at",
      "updated_at",
      "partners",
      "created_by",
    ];

    const formatValue = (val) => {
      if (Array.isArray(val))
        return val?.length > 0 ? val?.join(", ") : Translate("None");
      if (typeof val === "number") return val;
      if (val === null || val === undefined || val === "") return null;
      return val.toString();
    };

    if (
      typeof data?.properties?.old === "string" &&
      typeof data?.properties?.attributes === "string"
    ) {
      return (
        <span>
          &#10148; {data?.properties?.old?.replace(/_/g, " ")}{" "}
          <FaArrowRightLong style={{ marginBottom: "2px" }} />{" "}
          {data?.properties?.attributes?.replace(/_/g, " ")}
        </span>
      );
    }

    if (data?.properties?.old && data?.properties?.attributes) {
      const changes = Object.entries(data.properties.attributes)
        .filter(
          ([key]) =>
            !excludedKeys.some(
              (excludedKey) => key === excludedKey || key.endsWith("_id")
            ) && key !== "contributions"
        )
        .map(([key, newValue]) => {
          const oldValue = data.properties.old?.[key];
          const formattedKey = key
            ?.replace(/([A-Z])/g, " $1")
            ?.replace(/^./, (str) => str?.toUpperCase())
            ?.replace(/_/g, " ");

          const oldFormatted = formatValue(oldValue);
          const newFormatted = formatValue(newValue);

          if (oldFormatted === null && newFormatted !== null) {
            // Newly Created Field (no old value, only new value exists)
            return (
              <span key={key}>
                &#10148; {formattedKey}{" "}
                <FaArrowRightLong style={{ marginBottom: "2px" }} />{" "}
                {newFormatted}
              </span>
            );
          } else if (
            oldFormatted !== null &&
            newFormatted !== null &&
            oldFormatted !== newFormatted
          ) {
            // Edited Field (both old & new values exist, but changed)
            return (
              <span key={key}>
                &#10148; {formattedKey} - {oldFormatted}{" "}
                <FaArrowRightLong style={{ marginBottom: "2px" }} />{" "}
                {newFormatted}
              </span>
            );
          }
          return null;
        })
        .filter(Boolean);

      return changes.length > 0 ? (
        <div className="d-flex flex-column gap-1">{changes}</div>
      ) : (
        <p>{Translate("No changes detected")}</p>
      );
    }

    return <p>{Translate("No activity log available")}</p>;
  }

  return (
    <>
      {!loading && data?.activity_log?.length > 0 ? (
        <Row>
          <Col md={12}>
            <div
              className="widget-wrap "
              style={{
                maxHeight: height,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              <>
                {data?.activity_log.map((items, index) => {
                  return (
                    <Accordion key={index} activeKey={showDetails}>
                      <div className="activity-container-div" key={index}>
                        <div className="activity-container-wrapper">
                          <div className="d-flex justify-content-between align-item-center">
                            <div>
                              <span className="d-block">
                                <p className="fs--1 m-0 mb-1 text-uppercase text-danger fw-bold">
                                  {items?.created_at}
                                </p>
                              </span>
                              <span className="d-block pt-1">
                                {items?.event && (
                                  <Badge
                                    bg="warning"
                                    text="dark"
                                    className="d-inline text-capitalize"
                                  >
                                    {items?.event}
                                  </Badge>
                                )}
                                <span className="d-inline ps-2 text-capitalize">
                                  {items?.description?.charAt(0).toUpperCase() +
                                    items?.description?.slice(1).toLowerCase()}
                                </span>{" "}
                                {items?.properties?.old && (
                                  <MdExpandMore
                                    size={20}
                                    className="cursor-pointer mb-1"
                                    title="Details"
                                    onClick={() => handleDetails(items?.id)}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                              <span>
                                <Image
                                  src={
                                    items?.causer_image
                                      ? items?.causer_image
                                      : DefaultAvathar
                                  }
                                  height={25}
                                  width={25}
                                  alt="?"
                                  roundedCircle
                                />
                              </span>
                              <span className="custom-text-muted">
                                {items?.causer_name}
                              </span>{" "}
                            </div>
                          </div>
                          <span className="d-block pt-2">
                            <span className="d-block">
                              {items?.properties?.old?.status && (
                                <>
                                  <FaCircle size={7} className="d-inline" />
                                  <span className="d-inline ps-2">
                                    {Translate("Status")}:
                                    <p className="d-inline text-decoration-line-through ms-1 me-2">
                                      {items?.properties?.old?.status.replace(
                                        "_",
                                        " "
                                      )}
                                    </p>
                                    {items?.properties?.attributes?.status.replace(
                                      "_",
                                      " "
                                    )}
                                  </span>
                                </>
                              )}
                            </span>
                          </span>
                          {items?.properties?.old && (
                            <Accordion.Collapse
                              eventKey={items?.id}
                              className="mt-2"
                            >
                              <div className="bg-light p-1">
                                {displayActivityLog(items)}
                              </div>
                            </Accordion.Collapse>
                          )}
                        </div>
                      </div>
                    </Accordion>
                  );
                })}
                {nextLink &&
                  nextLink !== null &&
                  data?.activity_log?.length > 0 && (
                    <ActivityLoadMore
                      nextLink={nextLink}
                      loadNextPage={loadNextPage}
                    />
                  )}
              </>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {loading ? (
            <ActivityLogLoader height={height} items={10} />
          ) : (
            <div className="text-center w-100 mt-5">
              {Translate("No Activity Yet")} 😉
            </div>
          )}
        </>
      )}
    </>
  );
};
ActivityTimeLine.prototype = {
  infoData: PropTypes.any,
  triggerActivityLog: PropTypes.bool,
  itemId: PropTypes.string,
  height: PropTypes.string,
  customerId: PropTypes.string,
  userId: PropTypes.string,
};

export default ActivityTimeLine;
