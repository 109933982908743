import { React, useState } from "react";
import axios from "axios";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import FileController from "components/file-controller/FileController";
import { showToast } from "module/common/Toast/toast";
import ErrorAlert from "module/common/ErrorAlert";

const ImportModal = ({
  title,
  url,
  modalShow,
  handleCancel,
  setLoadAfterImport,
  params,
  sampleFileUrl,
}) => {
  const Translate = useAxisproTranslate();
  const [formData, setFormData] = useState({});
  const [onSave, setOnSave] = useState(false);
  const [onConfirm, setOnConfirm] = useState(false);
  const [saveOrConfirm, setSaveOrConfirm] = useState("save");
  const [confirmId] = useState();
  const [isInvalidData, setIsInvalidData] = useState(false);
  const [invalidFileUrl, setInvalidFileUrl] = useState();
  const [formError, setFormError] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleSubmit = () => {
    setOnSave(true);
    if (formData.file) {
      axios({
        method: "post",
        url: url,
        data: formData,
        params: {
          ...params,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const responseData = response?.data;
          if (responseData?.success === true) {
            showToast(responseData?.message, "success");
            handleCancel();
            setLoadAfterImport();
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
        })
        .catch((error) => {
          const errorData = error?.response?.data;
          if (errorData) {
            showToast(errorData?.message, "error");
            setIsInvalidData(true);
            setInvalidFileUrl(errorData?.data);
          }
        })
        .finally(() => {
          setOnSave(false);
        });
    } else {
      showToast("No file has been chosen. Please upload the file!", "error");
      setOnSave(false);
    }
  };

  const clickOnCancel = () => {
    handleCancel();
    setFormData({});
    setIsInvalidData(false);
    setSaveOrConfirm("save");
  };

  const handleConfirm = () => {
    setOnConfirm(true);
    axios({
      method: "post",
      url: "settings/import-file/" + confirmId,
      data: {
        confirmed: true,
      },
    })
      .then((response) => {
        const responseData = response?.data;
        if (responseData?.success === true) {
          showToast(responseData?.message, "success");
          if (responseData?.data?.file_url) {
            setIsInvalidData(true);
            setInvalidFileUrl(responseData?.data?.file_url);
          } else {
            handleCancel();
            setLoadAfterImport();
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        const errorData = error?.response?.data;
        showToast(
          errorData?.message ??
            error?.message ??
            "Something went wrong, please refresh the page and try again.",
          "error"
        );
      })
      .finally(() => {
        setOnConfirm(false);
      });
  };

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleFiles = (files) => {
    handleFieldChange({ target: { name: "file", value: files[0] } });
    setIsInvalidData(false);
  };

  const createFile = (url, file_name) => {
    setIsInvalidData(false);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } else {
      console.error("download file url is missing");
    }
  };

  const handleDownloadSampleFile = () => {
    if (isDownloading) return;
    setIsDownloading(true);
    axios({
      method: "get",
      url: sampleFileUrl,
    })
      .then((response) => {
        const responseData = response?.data;
        if (responseData?.success === true) {
          createFile(responseData?.data, title);
        }
      })
      .catch((error) => {
        const errorData = error?.response?.data;
        showToast(
          errorData?.message ??
            error?.message ??
            "Something went wrong, please refresh the page and try again.",
          "error"
        );
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal
        show={modalShow}
        onHide={clickOnCancel}
        className="modal-with-overlay"
      >
        <Modal.Header closeButton className={"fs-1"}>
          {Translate(title)}
        </Modal.Header>
        <Modal.Body>
          {!Object.keys(formError).length === 0 ? (
            <ErrorAlert setFormError={setFormError} formError={formError} />
          ) : (
            ""
          )}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <FileController
              limit={1}
              limitFeature={true}
              limitFileSizeInMB={5}
              limitFileType={["xlsx"]}
              onChange={handleFiles}
              className="my-3 "
              note={Translate("You can upload only one file at a time")}
              required={true}
            />
          </Form.Group>
          <p
            className="d-flex align-items-center cursor-pointer text-decoration-underline text-primary"
            onClick={handleDownloadSampleFile}
          >
            {isDownloading ? (
              <div className="d-flex align-items-center">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                  style={{ width: "1rem", height: "1rem" }}
                />
                {Translate("Downloading...")}
              </div>
            ) : (
              Translate("Download sample format")
            )}
          </p>
        </Modal.Body>

        <Modal.Footer
          className={isInvalidData ? "import-modal-footer-alignment" : ""}
        >
          {!isInvalidData ? (
            <p
              className="text-danger"
              hidden={saveOrConfirm === "confirm" ? false : true}
            >
              {Translate(
                "The uploaded sheet contains invalid rows values. Are you sure you want to continue with valid rows?"
              )}
            </p>
          ) : (
            <>
              {invalidFileUrl && (
                <p className="ps-1">
                  {Translate("The uploaded file contains invalid values.")}
                  <p
                    className="cursor-pointer text-decoration-underline text-primary mb-0"
                    onClick={() =>
                      createFile(invalidFileUrl, "Invalid " + title)
                    }
                  >
                    {Translate("Download Invalid File")}
                  </p>
                </p>
              )}
            </>
          )}

          {!isInvalidData ? (
            <>
              <Button
                size="sm"
                onClick={handleSubmit}
                variant="success"
                disabled={onSave || !formData.file}
                show={saveOrConfirm === "save" ? true : false}
                hidden={saveOrConfirm === "save" ? false : true}
              >
                {onSave ? (
                  <div className="d-flex align-items-center">
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      variant="white"
                      className="me-1"
                      style={{ width: "1rem", height: "1rem" }}
                    />
                    <span className="fs--1">{Translate("Processing")}...</span>
                  </div>
                ) : (
                  <span>{Translate("Import")}</span>
                )}
              </Button>

              <Button
                size="sm"
                onClick={handleConfirm}
                variant="warning"
                disabled={onConfirm}
                show={saveOrConfirm === "confirm" ? true : false}
                hidden={saveOrConfirm === "confirm" ? false : true}
              >
                {onConfirm ? (
                  <div className="d-flex align-items-center">
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      variant="white"
                      className="me-1"
                      style={{ width: "1rem", height: "1rem" }}
                    />
                    <span className="fs--1">{Translate("Processing")}...</span>
                  </div>
                ) : (
                  <span>{Translate("Confirm")}</span>
                )}
              </Button>
            </>
          ) : (
            ""
          )}

          <Button
            variant="primary"
            className={isInvalidData ? "ms-3" : ""}
            size="sm"
            onClick={clickOnCancel}
          >
            {Translate("Cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

ImportModal.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  modalShow: PropTypes.bool,
  handleCancel: PropTypes.func,
  setLoadAfterImport: PropTypes.func,
  params: PropTypes.object,
  sampleFileUrl: PropTypes.string,
};

export default ImportModal;
