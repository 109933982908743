import axios from "axios";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import BackButton from "components/common/BackButton";
import FileController from "components/file-controller/FileController";
import SelectCustomer from "components/form/SelectCustomer";
import SelectPriority from "components/form/SelectPriority";
import SelectProduct from "components/form/SelectProduct";
import SelectProject from "components/form/SelectProject";
import SelectTicketOrigin from "components/form/SelectTicketOrigin";
import SelectTicketStatus from "components/form/SelectTicketStatus";
import SelectTicketType from "components/form/SelectTicketType";
import SelectUsers from "components/form/SelectUsers";
import { MODULES } from "constants/Constants";
import { AuthWizardContext, StoreContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import { ticketsFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import { SaveButton, SaveNewButton } from "module/common/Buttons/CommonButton";
import ListLoading from "module/common/ListLoading";
import TextEditor from "module/common/TextEditor/TextEditor";
import { showToast } from "module/common/Toast/toast";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BiSupport } from "react-icons/bi";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

const TicketForm = ({
  show = true,
  handleCloseForm,
  openFrom,
  ticketId,
  fetchData,
}) => {
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  let navigate = useNavigate();
  const [getData] = useOutletContext() || [{}];
  const [formData, setFormData] = useState(ticketsFormKeys);
  const [customerId, setCustomerId] = useState({
    temp_customer_id: "",
    customer_id: "",
    customer_id_ref: { value: "", label: "" },
  });
  const [billableStatus, setBillableStatus] = useState(1);
  const [formError, setFormError] = useState({});
  let { itemId, itemInfoId, empId } = useParams();
  itemId = itemId ? itemId : ticketId;
  const { assigneeId, assigneeName } = allQueryParams;
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [onSaveNew, setOnSaveNew] = useState(false);
  const location = useLocation();
  const pageNumber = queryParams.get("page");
  const locationPathName = useLocation();
  const { store, addIitemToStore } = useContext(StoreContext);
  const triggerFromQuickLinkTicket = store?.triggerFromQuickLinkTicket ?? false;
  const mode =
    locationPathName?.pathname?.includes("/add") ||
    locationPathName?.pathname?.includes("/edit") ||
    locationPathName?.pathname?.includes("/update");
  const renderFrom = location.pathname.split("/")[1];
  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      if (e.target.name === "status") {
        value = e.target.checked ? "Active" : "Inactive";
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      const editor = document.querySelector(".ql-editor");
      if (editor) {
        const images = editor.querySelectorAll("img");
        images.forEach((image) => {
          image.style.width = "150px";
          image.style.height = "auto";
        });
      }
      if (action.action === "select-option") {
        if (action.name === "customer_id") {
          formData.project_id = itemInfoId ? formData.project_id : "";
          formData.project_id_ref = { label: "", value: "" };
        }
        value = e.value ? e.value : "";
      } else if (action.action === "clear") {
        if (action.name === "customer_id") {
          formData.project_id = "";
          formData.project_id_ref = { label: "", value: "" };
        }
        value = "";
      } else if (action.action === "text-editor") {
        value = action.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }

    setFormData(newFormData);
  };

  const handleBillableChange = (e) => {
    setBillableStatus(Number(e.target.value));
  };

  const handleClose = () => {
    if (
      openFrom === "quick_link" ||
      openFrom === "ticket_overview" ||
      openFrom === "ticket_offcanvas" ||
      openFrom === "dashboard"
    ) {
      handleCloseForm();
    } else {
      navigate(-1);
    }
  };

  const handleCustomerData = (e, action) => {
    if (action?.action === "select-option") {
      setFormData((prev) => ({
        ...prev,
        project_id: itemInfoId ? formData?.project_id : "",
        project_id_ref: { label: "", value: "" },
      }));
      setCustomerId({
        temp_customer_id: e.value,
        customer_id: e.value,
        customer_id_ref: e,
      });
    } else {
      setCustomerId({
        temp_customer_id: "",
        customer_id: "",
        customer_id_ref: { value: "", label: "" },
      });
    }
  };

  const handleFiles = (files) => {
    handleFieldChange({ target: { name: "files", value: files } });
  };

  const handleSubmit = (e, saveAndNew) => {
    e.preventDefault();
    if (onSaveNew || onSave) return;
    saveAndNew ? setOnSaveNew(true) : setOnSave(true);
    setFormError({});
    const formDataWithoutRefkeys = removeRefData({
      ...formData,
      billable: billableStatus,
    });
    axios({
      method: openFrom !== "quick_link" && itemId ? "put" : "post",
      url: `crm/ticket${
        openFrom !== "quick_link" && itemId ? "/" + itemId : ""
      }`,
      data: formDataWithoutRefkeys,
      headers: {
        "Content-Type":
          !itemId || openFrom === "quick_link"
            ? "multipart/form-data"
            : "application/json",
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          if (saveAndNew) {
            setFormData((prev) => ({
              ...prev,
              description: ticketsFormKeys.description,
            }));
            if (getData && typeof getData === "function") {
              getData(
                itemId || itemInfoId ? pageNumber : 1,
                itemId ? false : true
              );
            }
            if (fetchData && typeof fetchData === "function") {
              fetchData();
            }
            if (openFrom === "quick_link") {
              addIitemToStore(
                "triggerFromQuickLinkTicket",
                !triggerFromQuickLinkTicket
              );
            }
            setTimeout(() => {
              setFormData((prev) => ({
                ...prev,
                title: ticketsFormKeys.title,
              }));
            }, 300);
          } else {
            setFormData(ticketsFormKeys);
            if (openFrom === "quick_link") {
              addIitemToStore(
                "triggerFromQuickLinkTicket",
                !triggerFromQuickLinkTicket
              );
              handleClose();
            } else if (
              openFrom === "ticket_overview" ||
              openFrom === "ticket_offcanvas"
            ) {
              if (fetchData && typeof fetchData === "function") {
                fetchData();
              }
              handleClose();
            } else if (openFrom === "dashboard") {
              navigate(`/tickets/list/overview/${response?.data?.data?.id}`);
              handleClose();
            } else {
              if (getData && typeof getData === "function") {
                getData(
                  itemId || itemInfoId ? pageNumber : 1,
                  itemId ? false : true
                );
              }
              if (location.pathname.includes("employee/overview")) {
                delete allQueryParams.assigneeId;
                delete allQueryParams.assigneeName;
                navigate(
                  itemId || itemInfoId
                    ? -1
                    : `/employee/overview/${empId}?${createSearchParams({
                        ...allQueryParams,
                      })}`
                );
              } else {
                navigate(
                  itemId || itemInfoId
                    ? -1
                    : `/tickets/list?${createSearchParams({
                        ...allQueryParams,
                      })}`
                );
              }
            }
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error?.response?.data &&
            error?.response?.data?.data &&
            error?.response?.data?.data?.errors
              ? error?.response?.data?.data?.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .finally(() => {
        saveAndNew ? setOnSaveNew(false) : setOnSave(false);
      });
  };

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/ticket/" + itemId,
    });

    setFormData({
      title: data.title,
      project_id: data?.project_id,
      project_id_ref: { label: data?.project, value: data?.project_id },
      ticket_type_id: data?.ticket_type_id,
      ticket_type_id_ref: {
        label: data?.ticket_type,
        value: data?.ticket_type_id,
      },
      priority: data?.priority,
      priority_ref: {
        label: data?.priority,
        value: data?.priority,
      },
      ticket_origin: data.ticket_origin,
      ticket_origin_ref: {
        label: data.ticket_origin,
        value: data.ticket_origin,
      },
      status: data?.status,
      status_ref: { label: data?.status, value: data?.status },
      description: data.description,
      assigned_to: data?.assigned_to,
      assigned_to_ref: { label: data?.assignee, value: data.assigned_to },
      ticket_date_time: data?.ticket_date_time ?? "",
      end_date_time: data?.end_date_time ? data?.end_date_time : "",
      product_id: data?.product_id ?? "",
      product_id_ref: {
        label: data?.product_title ?? "",
        value: data?.product_id ?? "",
      },
    });
    setCustomerId({
      customer_id: data?.customer_id,
      customer_id_ref: {
        label: data?.customer_name,
        value: data?.customer_id,
      },
    });
    setBillableStatus(data?.billable);
    setLoading(false);
  };

  const getTicketType = async () => {
    const data = await apiCall({
      url: "crm/ticket-type-autocomplete",
    });
    if (data && Array.isArray(data) && data.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        ticket_type_id: data[0]?.id,
        ticket_type_id_ref: {
          label: data[0]?.title,
          value: data[0]?.id,
        },
      }));
    }
  };

  const getProject = async (id) => {
    const data = await apiCall({
      url: "crm/project-autocomplete",
      params: {
        customer_id: id,
      },
    });
    if (data && Array.isArray(data) && data.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        project_id: data[0]?.id,
        project_id_ref: {
          label: data[0]?.title,
          value: data[0]?.id,
        },
      }));
    }
  };

  useEffect(() => {
    if (openFrom !== "quick_link" && itemId) {
      getDataItem();
    }
    if (openFrom === "quick_link" || !itemId) {
      getTicketType();
    }

    return () => {
      setOnSave(false);
      setFormData(ticketsFormKeys);
      setFormError({});
      setCustomerId({
        temp_customer_id: "",
        customer_id: "",
        customer_id_ref: { value: "", label: "" },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (assigneeId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        assigned_to: user?.customer_id ? undefined : assigneeId,
        assigned_to_ref: {
          label: user?.customer_id ? "" : assigneeName,
          value: user?.customer_id ? "" : assigneeId,
        },
      }));
    } else if (user) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        assigned_to: user?.customer_id ? undefined : user?.id,
        assigned_to_ref: {
          label: user?.customer_id ? "" : user?.name,
          value: user?.customer_id ? "" : user?.id,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, assigneeId]);

  useEffect(() => {
    if (openFrom !== "quick_link" && itemInfoId) {
      setFormData((prev) => ({
        ...prev,
        project_id: itemInfoId,
        priority: "Medium",
        ticket_origin: "web",
        status: "new",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfoId]);

  useEffect(() => {
    if (customerId?.temp_customer_id) {
      getProject(customerId?.temp_customer_id);
    }
  }, [customerId?.temp_customer_id]);

  const getComponets = (field) => {
    switch (field) {
      case "end_date_time":
        return (
          <>
            {user?.customer_id ? null : (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label>{Translate("End Date Time")}</Form.Label>
                <AppTimePicker
                  name="end_date_time"
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  hourPlaceholder="hh"
                  minutePlaceholder="mm"
                  onChange={handleFieldChange}
                  value={formData.end_date_time}
                  isInvalid={!!formError.end_date_time}
                />
                <Form.Control.Feedback type="is-invalid">
                  {formError.end_date_time}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </>
        );

      case "billableStatus":
        return (
          <Form.Group className="mb-2">
            <Form.Label>{Translate("Billable Status")}</Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                label="Billable"
                name="billableStatus"
                value={1}
                checked={billableStatus === 1}
                onChange={handleBillableChange}
                id="billable-radio"
              />
              <Form.Check
                inline
                type="radio"
                label="Non-billable"
                name="billableStatus"
                value={0}
                checked={billableStatus === 0}
                onChange={handleBillableChange}
                id="non-billable-radio"
              />
            </div>
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal show={show} size="lg" className="no-border-radius-modal">
        <Modal.Header>
          <Modal.Title className="w-100 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <BiSupport size={20} className="me-2 text-dark" />
              <span
                className="custom-module-header text-uppercase"
                style={{ fontSize: "15px" }}
              >
                {Translate(
                  openFrom !== "quick_link" && itemId
                    ? "UPDATE TICKET"
                    : "CREATE TICKET"
                )}
              </span>
            </div>
            <div className="d-flex justify-content-end">
              <CustomBackButton
                variant="falcon-default"
                title="Close"
                onClick={handleClose}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="require-data">
                      {Translate("Ticket Title")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      className="pt-1 pb-1"
                      onChange={handleFieldChange}
                      value={formData.title}
                      placeholder="Enter Name"
                      isInvalid={!!formError.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {openFrom !== "quick_link" &&
                  (itemInfoId || user?.customer_id) ? null : (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="require-data">
                        {Translate("Client")}
                      </Form.Label>
                      <SelectCustomer
                        name="customer_id"
                        value={customerId?.customer_id_ref}
                        error={formError?.customer_id}
                        handleFieldChange={handleCustomerData}
                        clearable={false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.customer_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  {openFrom !== "quick_link" && itemInfoId ? null : (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="require-data">
                        {Translate("Project")}
                      </Form.Label>
                      <SelectProject
                        name="project_id"
                        value={formData.project_id_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError.project_id}
                        clientId={
                          openFrom !== "quick_link" && itemInfoId
                            ? ""
                            : customerId.customer_id
                        }
                        defaultLoadOptions={formData.customer_id ? true : false}
                        clearable={false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.project_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="require-data">
                      {Translate("Ticket Type")}
                    </Form.Label>
                    <SelectTicketType
                      value={formData.ticket_type_id_ref}
                      name="ticket_type_id"
                      handleFieldChange={handleFieldChange}
                      error={!!formError.ticket_type_id}
                      clearable={false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.ticket_type_id}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {!user?.customer_id && (
                    <>
                      {openFrom !== "quick_link" &&
                      itemInfoId &&
                      user?.customer_id ? null : (
                        <>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput3"
                          >
                            <Form.Label>
                              {Translate("Start Date Time")}
                            </Form.Label>
                            <AppTimePicker
                              name="ticket_date_time"
                              yearPlaceholder="yyyy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              hourPlaceholder="hh"
                              minutePlaceholder="mm"
                              onChange={handleFieldChange}
                              value={formData.ticket_date_time}
                              isInvalid={!!formError.ticket_date_time}
                              showDefaultDate={true}
                            />
                            <Form.Control.Feedback type="is-invalid">
                              {formError.ticket_date_time}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </>
                      )}
                    </>
                  )}
                  {renderFrom === MODULES.PROJECT &&
                    openFrom !== "quick_link" &&
                    getComponets("end_date_time")}

                  {!user?.customer_id && (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{Translate("Product")}</Form.Label>
                      <SelectProduct
                        name="product_id"
                        value={formData.product_id_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError.product}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.product}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
                {!user?.customer_id && (
                  <Col md={6}>
                    {openFrom !== "quick_link" &&
                    itemInfoId &&
                    user?.customer_id ? null : (
                      <>
                        {renderFrom !== MODULES.EMPLOYEE && (
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>{Translate("Assignee")}</Form.Label>
                            <SelectUsers
                              value={formData.assigned_to_ref}
                              name="assigned_to"
                              handleFieldChange={handleFieldChange}
                              error={formError.assigned_to}
                              disabled={empId ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formError.assigned_to}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="require-data">
                            {Translate("Status")}
                          </Form.Label>
                          <SelectTicketStatus
                            value={formData.status_ref}
                            name="status"
                            handleFieldChange={handleFieldChange}
                            error={!!formError.status}
                            clearable={false}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.status}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{Translate("Ticket Origin")}</Form.Label>
                          <SelectTicketOrigin
                            value={formData.ticket_origin_ref}
                            name="ticket_origin"
                            handleFieldChange={handleFieldChange}
                            error={!!formError.ticket_origin}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.ticket_origin}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{Translate("Priority")}</Form.Label>
                          <SelectPriority
                            name="priority"
                            value={formData.priority_ref}
                            handleFieldChange={handleFieldChange}
                            error={formError.priority}
                            fetchUrl="crm/ticket-priority-list"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.priority}
                          </Form.Control.Feedback>
                        </Form.Group>

                        {openFrom === "dashboard" &&
                          getComponets("end_date_time")}

                        {renderFrom === MODULES.EMPLOYEE &&
                          getComponets("billableStatus")}

                        {renderFrom === MODULES.TICKET &&
                          getComponets("end_date_time")}

                        {renderFrom === MODULES.EMPLOYEE &&
                          getComponets("end_date_time")}

                        {openFrom === "quick_link" &&
                          renderFrom !== MODULES.TICKET &&
                          renderFrom !== MODULES.EMPLOYEE &&
                          getComponets("end_date_time")}

                        {user?.customer_id || renderFrom === MODULES.EMPLOYEE
                          ? null
                          : getComponets("billableStatus")}
                      </>
                    )}
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <div className="text-dark">
                    <Form.Label>{Translate("Description")}</Form.Label>
                    <TextEditor
                      name="description"
                      handleFieldChange={handleFieldChange}
                      value={formData.description}
                      isInvalid={!!formError.description}
                    />
                  </div>

                  {(!itemId || openFrom) && (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <FileController
                        limit={5}
                        limitFeature={true}
                        limitFileSizeInMB={5}
                        limitFileType={[
                          "jpg",
                          "png",
                          "jpeg",
                          "pdf",
                          "excel",
                          "xlsx",
                          "docx",
                          "doc",
                          "xls",
                        ]}
                        onChange={handleFiles}
                        className="my-3"
                        note="You can upload a maximum of 5 files, 5MB each"
                      />
                    </Form.Group>
                  )}

                  <Form.Group className="d-flex justify-content-end gap-1 mt-2">
                    <SaveButton
                      onSave={onSave}
                      handleSubmit={(e) => handleSubmit(e, false)}
                      id={openFrom !== "quick_link" && itemId}
                    />
                    {!itemId && (
                      <SaveNewButton
                        handleSubmit={(e) => handleSubmit(e, true)}
                        onSave={onSaveNew}
                      />
                    )}
                    <BackButton
                      variant={"danger"}
                      size={"sm"}
                      disabled={onSave}
                      onClick={handleClose}
                    >
                      {Translate("Cancel")}
                    </BackButton>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          ) : (
            <div className="mb-2">
              <ListLoading style={{ height: "53.1vh", overflow: "hidden" }} />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TicketForm;
