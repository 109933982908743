import {
  React,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { apiCall } from "helpers/apiCalls";
import authReducer from "reducers/authReducer";
import { AuthWizardContext } from "context/Context";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import axios from "axios";
import { Alert } from "react-bootstrap";
import echo from "helpers/echo";

const AuthProvider = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [user, dispatch] = useReducer(
    authReducer,
    useContext(AuthWizardContext)
  );
  const userId = user?.id ?? "";
  const stringifiedUser = JSON.stringify(user ?? {});
  const [userDataFromStore, setUserDataFromStore] = useState(user ?? {});
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    if (userId) {
      const parsedUser = JSON.parse(stringifiedUser);
      setUserDataFromStore(parsedUser);
      setIsUserLoaded(true);
    }
  }, [userId, stringifiedUser]);

  const [invalidHost, setInvalidHost] = useState(false);
  const urlObject = new URL(window.location.href);
  const lockScreen = () => {
    dispatch({ type: "UPDATE", payload: { ...user, lockScreen: true } });
  };
  let assignedPermission = JSON.parse(
    localStorage.getItem("ACODAX_CRM_PERMISSION")
  );
  let userInfo = JSON.parse(localStorage.getItem("AXIS_PRO_CRM_USER"));
  const getAssignedPermission = async () => {
    if (userInfo && !assignedPermission) {
      try {
        const { data } = await axios.get(`auth/permissions`);
        if (Array.isArray(data?.data)) {
          localStorage.setItem(
            "ACODAX_CRM_PERMISSION",
            JSON.stringify(data.data)
          );
        }
        setloading(false);
      } catch (error) {
        setloading(false);
        console.log(error);
      }
    }
  };
  const getUserInfo = async () => {
    if (
      (process.env.REACT_APP_API_DEVELOPMENT_URL &&
        process.env.REACT_APP_API_CUSTOM_MODE === "DEV") ||
      process.env.REACT_APP_API_CUSTOM_REQUEST_URL === urlObject.origin
    ) {
      axios.defaults.baseURL = process.env.REACT_APP_API_DEVELOPMENT_URL;
    } else {
      try {
        const apiEndPointDetails = await axios.get(
          process.env.REACT_APP_SET_API_URL,
          {
            headers: {
              withCredentials: true,
              settingBaseUrl: true,
            },
          }
        );

        if (!apiEndPointDetails?.data?.api_url) {
          console.error("Invalid Host.!");
          setInvalidHost(true);
          return;
        }

        axios.defaults.baseURL = apiEndPointDetails.data.api_url;
      } catch (error) {
        console.error("Invalid Host.!");
        setInvalidHost(true);
        return;
      }
    }
    let userData = JSON.parse(localStorage.getItem("AXIS_PRO_CRM_USER"));
    let data = null;
    if (userData) {
      if (!assignedPermission) getAssignedPermission();
      try {
        // validate token
        data = await apiCall({
          url: "user/info",
          params: {
            app_name: "CRM",
          },
        });

        const timerData = await apiCall({
          url: "crm/running-tasks-info",
        });

        //update timer data
        data.running_tasks_count = timerData?.count;
        data.running_task_id =
          timerData?.tasks?.length > 0 ? timerData?.tasks[0].id : null;

        dispatch({
          type: "UPDATE",
          payload: data,
        });
      } catch (error) {
        console.log(error);
      }
    }
    setloading(false);
  };

  const handleNotificationData = (data) => {
    const currentTimeStamp = Date.now();
    const storageKey = "notificationData";

    let notificationData;

    let notificationDataFromLocalStorage = JSON.parse(
      localStorage.getItem(storageKey)
    );

    if (!notificationDataFromLocalStorage) {
      notificationData = {
        id: currentTimeStamp,
        startTime: currentTimeStamp,
        data: [data],
      };
      localStorage.setItem(storageKey, JSON.stringify(notificationData));
    } else {
      notificationData = notificationDataFromLocalStorage;

      if (
        !notificationData.data.some(
          (item) => JSON.stringify(item) === JSON.stringify(data)
        )
      ) {
        notificationData.data.push(data);
        localStorage.setItem(storageKey, JSON.stringify(notificationData));
      }
    }
  };

  const checkLocalStorageForTimeout = useCallback(() => {
    const storageKey = "notificationData";
    const fiveMinutes = 5 * 60 * 1000;
    const currentTimeStamp = Date.now();

    let notificationDataFromLocalStorage = JSON.parse(
      localStorage.getItem(storageKey)
    );

    if (notificationDataFromLocalStorage) {
      if (
        currentTimeStamp - notificationDataFromLocalStorage.startTime >=
        fiveMinutes
      ) {
        const updatedUser = {
          ...userDataFromStore,
          taskNotificationData: notificationDataFromLocalStorage,
          showTaskNotificationPopup: true,
        };
        dispatch({
          type: "UPDATE",
          payload: updatedUser,
        });
        localStorage.removeItem(storageKey);
      }
    }
  }, [userDataFromStore, dispatch]);
  const setupTimeoutCheck = useCallback(
    (delay) => {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        checkLocalStorageForTimeout();
        setupTimeoutCheck(delay);
      }, delay || 1000);
    },
    [checkLocalStorageForTimeout]
  );

  useEffect(() => {
    if (isUserLoaded) {
      setupTimeoutCheck(1000);
    }

    return () => clearTimeout(timeoutIdRef.current);
  }, [isUserLoaded, setupTimeoutCheck]);

  const connectWebSocket = (channelName) => {
    const channel = echo.channel(channelName);
    channel.listen("AcodaxAppUserBroadCast", (data) => {
      if (data?.type === "permissions.updated") {
        getAssignedPermission();
      } else if (data?.type === "notify.assignee") {
        handleNotificationData(data);
      }
    });
    channel.error((error) => {
      console.error("WebSocket Error:", error);
    });
  };
  useEffect(() => {
    if ((userInfo?.branch_id || userInfo?.customer_id) && userInfo?.id) {
      if (!assignedPermission) {
        setloading(true);
        getAssignedPermission();
      }
      const channelName = `app-broadcast-${userInfo.branch_id}-${userInfo.id}`;
      connectWebSocket(channelName);
      return () => {
        echo.leaveChannel(channelName);
      };
    } else if (userInfo?.logged_as === "customer") {
      if (Array.isArray(userInfo?.permissions)) {
        localStorage.setItem(
          "ACODAX_CRM_PERMISSION",
          JSON.stringify(userInfo?.permissions)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userInfo?.branch_id,
    userInfo?.id,
    userInfo?.logged_as,
    userInfo?.customer_id,
  ]);

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWizardContext.Provider
      value={{
        user,
        dispatch,
        lockScreen,
      }}
    >
      {loading ? (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          {invalidHost ? (
            <Alert variant="danger">Invalid host.!</Alert>
          ) : (
            <LoadingCommon />
          )}
        </div>
      ) : (
        children
      )}
    </AuthWizardContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
export default AuthProvider;
