import { React } from "react";
import { Col, Row } from "react-bootstrap";
import WeeklyTaskSummary from "./WeeklyTaskSummary";
import TaskCompletionRate from "./TaskCompletionRate";

function TaskSummaryTilesGroup({ taskSummary, taskSummaryLoading }) {
  return (
    <Row className="g-3" style={{ height: "100%" }}>
      <Col xxl={12} xl={12} lg={12} md={6}>
        <WeeklyTaskSummary />
      </Col>
      <Col xxl={12} xl={12} lg={12} md={6}>
        <TaskCompletionRate
          dashboardData={taskSummary}
          loading={taskSummaryLoading}
        />
      </Col>
    </Row>
  );
}

export default TaskSummaryTilesGroup;
