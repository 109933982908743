import React from "react";
import PropTypes from "prop-types";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardDropdown from "components/common/CardDropdown";
import IconItem from "components/common/icon/IconItem";
import StatsChart from "./StatsChart";
import { getColor } from "helpers/utils";
import { Card } from "react-bootstrap";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import NothingToShow from "../NothingToShow";

const CrmStatsItem = ({
  loading,
  title,
  heading,
  target,
  icon,
  color,
  caretColor,
  caret,
  data,
  amount,
  handleAction,
  permission,
}) => {
  const Translate = useAxisproTranslate();
  const dropdownClick = (action) => {
    handleAction(action, title);
  };

  const permissionCheck = {
    show:
      (permission?.showLead && title === "Crm Lead") ||
      (permission?.showOpportunity && title === "Crm Opportunity") ||
      (permission?.showUser && title === "User"),
  };

  return (
    <>
      {loading ? (
        <Card.Body className="p-2">
          <DashboardTileLoader column={1} />
        </Card.Body>
      ) : (
        <>
          <Flex justifyContent="center" alignItems="center" className="mb-3">
            <IconItem
              tag="div"
              icon={icon}
              bg={`soft-${color}`}
              color={color}
              size="sm"
              className="me-2 shadow-none"
            />
            <h6 className="mb-0 flex-1 fw-bold fs-0 p-0">
              {Translate(heading)}
            </h6>
            {permissionCheck?.show && (
              <CardDropdown
                handleAction={dropdownClick}
                permission={permissionCheck}
              />
            )}
          </Flex>
          {data?.length > 0 ? (
            <Flex>
              <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{amount}%</p>
              <div className="d-flex flex-column">
                <FontAwesomeIcon
                  icon={caret}
                  className={`me-1 mb-0 text-${caretColor}`}
                />
                <p className="fs--2 mb-0 mt-0 text-nowrap">
                  {Translate(target)}
                </p>
              </div>
              <div className="w-100 ms-2">
                <StatsChart color={getColor(color)} data={data} />
              </div>
            </Flex>
          ) : (
            <div className="p-0 mb-0">
              <NothingToShow />
            </div>
          )}
        </>
      )}
    </>
  );
};

CrmStatsItem.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]).isRequired,
  color: PropTypes.string.isRequired,
  caretColor: PropTypes.string.isRequired,
  caret: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  amount: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  permission: PropTypes.object.isRequired,
};

export default CrmStatsItem;
