import React, { useContext, useState } from "react";
import StatusCarousel from "../StatusCarousel/StatusCarousel";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import getTicketStatusWithProps from "module/common/helpers/getTicketStatusWithProps";
import { BsHeadset } from "react-icons/bs";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AuthWizardContext } from "context/Context";
import TicketForm from "module/Tickets/TicketForm/TicketForm";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";

function TicketSummary({ ticketSummary, ticketSummaryLoading }) {
  const { user } = useContext(AuthWizardContext);
  const navigate = useNavigate();
  const [showTicketForm, setShowTicketForm] = useState(false);

  const statusArray =
    Object.keys(ticketSummary).length > 0
      ? Object.entries(ticketSummary?.status_count ?? {})
          .map(([key, value]) => ({
            key,
            value,
            label: getTicketStatusWithProps(key, "label"),
            icon: getTicketStatusWithProps(key, "icon"),
          }))
          .filter((status) => status.key !== "all")
      : [];

  const totalCount = {
    key: "total_count",
    value: ticketSummary?.status_count?.all ?? 0,
    label: "Total Tickets",
    icon: <BsHeadset color={"#00a9fe"} size={25} className="mb-1" />,
  };

  const permission = {
    store: GetAcodaxPermission("TICKET", "store"),
    show: GetAcodaxPermission("TICKET", "list"),
  };

  const handleList = () => {
    if (permission?.show) {
      navigate(
        `/tickets/list?${createSearchParams({
          assignee: user?.id,
          assignee_ref: JSON.stringify({
            value: user?.id,
            label: user?.name,
          }),
          order_by: "desc",
          order_by_ref: JSON.stringify({
            value: "desc",
            label: "Descending",
          }),
        })}`
      );
    }
  };

  const handleAction = (e, action, data) => {
    e.stopPropagation();
    if (action === "navigation") {
      if (data.key === "total_count") {
        handleList();
      } else {
        if (permission?.show) {
          navigate(
            `/tickets/list?${createSearchParams({
              assignee: user?.id,
              assignee_ref: JSON.stringify({
                value: user?.id,
                label: user?.name,
              }),
              order_by: "desc",
              order_by_ref: JSON.stringify({
                value: "desc",
                label: "Descending",
              }),
              status: data.key,
              status_ref: JSON.stringify({
                value: data.key,
                label: data?.key.replace(/_/g, " "),
              }),
            })}`
          );
        }
      }
    } else if (action === "store") {
      if (permission?.store) {
        setShowTicketForm(true);
      }
    } else if (action === "list") {
      handleList();
    }
  };

  return (
    <>
      <Row>
        <StatusCarousel
          statusArray={statusArray}
          totalCount={totalCount}
          loading={ticketSummaryLoading}
          loadingBox={6}
          permission={permission}
          countLabel="Tickets"
          handleAction={handleAction}
          store
          list
        />
      </Row>
      {showTicketForm && (
        <TicketForm
          show={showTicketForm}
          handleCloseForm={() => setShowTicketForm(false)}
          openFrom="quick_link"
        />
      )}
    </>
  );
}

TicketSummary.propTypes = {
  ticketSummary: PropTypes.any,
  ticketSummaryLoading: PropTypes.bool,
};

export default TicketSummary;
